import React from "react"
import styled from "styled-components/macro"
import { Link } from "react-router-dom"

import { Helmet } from "react-helmet-async"

import { Button as MuiButton, Typography } from "@material-ui/core"
import { spacing } from "@material-ui/system"

import { MuiButtonSpacingType } from "../../types/styles"

const Button = styled(MuiButton)<MuiButtonSpacingType>(spacing)

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px;
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`
// Invalid Token
function Page498() {
  return (
    <Wrapper>
      <Helmet title="Error : Invalid Token" />
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        Invalid Token
      </Typography>
    </Wrapper>
  )
}

export default Page498
