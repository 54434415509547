import styled from "styled-components"

import AppTxt from "components/commons/Typography"
import Typography from "../commons/Typography"

import { DocumentConfig } from "./UploadDocumentCard"

const Container = styled.div`
  margin-top: 24px;
  margin-bottom: 40px;
`

const List = styled.ul`
  margin-top: 8px;
  margin-left: -16px;
`

export interface RequiredDocumentCardProps {
  documents: DocumentConfig[]
}

export const RequiredDocumentCard = (props: RequiredDocumentCardProps) => {
  const { documents } = props

  return (
    <Container>
      {documents.length > 0 && (
        <Typography variant="h3" color="Text/Black2">
          เอกสารที่ต้องใช้เบิก
        </Typography>
      )}
      <List>
        {documents.map((doc, ind) => (
          <li key={ind}>
            <AppTxt variant="h4" color="SecondaryText/Gray">
              {doc.title}
            </AppTxt>
          </li>
        ))}
      </List>
    </Container>
  )
}
