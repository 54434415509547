import { ReactComponent as CaseIcon } from "assets/icon/case.svg"
import { ReactComponent as CaseBlueIcon } from "assets/icon/case_blue.svg"
import { ReactComponent as DatabaseIcon } from "assets/icon/database.svg"
import { ReactComponent as DatabaseBlueIcon } from "assets/icon/database_blue.svg"
import { ReactComponent as ListIcon } from "assets/icon/list_icon.svg"
import { Box, Grid } from "components"
import Typography from "components/commons/Typography"
import { PropsWithChildren } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { AppColor } from "theme/app-color"

const Footer = styled(Box)`
  position: fixed;
  border-top: 1px solid ${AppColor["Gray/Card Border, Line"]};
  bottom: 0;
  left: 0;
  right: 0;
  text-align: "center";
  background: ${AppColor["White / White"]};
  max-width: 752px;
`

interface PageFooterProps extends PropsWithChildren<unknown> {
  page?: string
  isHr?: boolean
}

export const PageFooter = (props: PageFooterProps) => {
  const { page, isHr } = props

  return (
    <Footer overflow="hidden" mx="auto">
      <Grid container>
        <Grid item xs={isHr ? 4 : 6}>
          <Link to="/home" style={{ textDecoration: "none" }}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              padding="8px 0px 8px 16px"
            >
              <Box mt="3px">{page === "home" ? <DatabaseBlueIcon /> : <DatabaseIcon />}</Box>
              <Box mb="4px">
                <Typography
                  variant={page === "home" ? "body2" : "subtitle1"}
                  color={page === "home" ? "Text/Blue" : "Text/Gray"}
                >
                  E-claim
                </Typography>
              </Box>
            </Box>
          </Link>
        </Grid>
        <Grid item xs={isHr ? 4 : 6}>
          <Link to="/claim-list" style={{ textDecoration: "none" }}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              padding={isHr ? "8px 0px 8px 0px" : "8px 16px 8px 0px"}
            >
              <Box mt="2.4px">{page === "claim-list" ? <CaseBlueIcon /> : <CaseIcon />}</Box>
              <Box mb="4px">
                <Typography
                  variant={page === "claim-list" ? "body2" : "subtitle1"}
                  color={page === "claim-list" ? "Text/Blue" : "Text/Gray"}
                >
                  เบิกสวัสดิการ
                </Typography>
              </Box>
            </Box>
          </Link>
        </Grid>
        {isHr && (
          <Grid item xs={4}>
            <Link to="/dashboard" style={{ textDecoration: "none" }}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                padding="8px 16px 8px 0px"
              >
                <Box mt="0px">
                  <ListIcon fill={page === "dashboard" ? AppColor["Text/Blue"] : AppColor["Text/Gray"]} />
                </Box>
                <Box mb="4px">
                  <Typography
                    variant={page === "dashboard" ? "body2" : "subtitle1"}
                    color={page === "dashboard" ? "Text/Blue" : "Text/Gray"}
                  >
                    รายการขอเบิก
                  </Typography>
                </Box>
              </Box>
            </Link>
          </Grid>
        )}
      </Grid>
    </Footer>
  )
}
