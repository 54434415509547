import { Box, Button, Divider, Grid } from "components"
import { IconList } from "components/advance/IconList"
import { Icon } from "components/commons/Icon"
import Typography from "components/commons/Typography"
import dayjs from "dayjs"
import { compose, withFormik, withHooks } from "enhancers"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { AppColor } from "theme/app-color"
import { toCurrency } from "utils/helper"

const Container = styled.div<{
  row?: boolean
  height?: string
  width?: string
}>`
  background-color: ${AppColor["White / White"]};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 8px;
  border-radius: 8px;
`

const ContainerFlex = styled.div<{ js: string; row?: boolean; w100?: boolean; al: string }>`
  display: flex;
  justify-content: ${(props) => props.js};
  align-items: ${(props) => props.al};
  flex-direction: ${(props) => (props.row ? "row" : "column")};
  width: ${(props) => (props.w100 ? "100%" : "none")};
`

const SummaryItems = styled.div`
  margin-top: 16px;
  padding: 16px;
  background: ${AppColor["White / White"]};
  border: 0.5px solid ${AppColor["Gray/Flat Button Bg"]};
  box-shadow: 0px 4px 4px rgba(218, 218, 218, 0.25);
  border-radius: 8px;
`

const SummaryItem1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin: 0px 0px 8px 0px;
`

const SummaryItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin: 8px 0px 0px 0px;
`

const Categories = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  border-radius: 4px;
  height: fit-content;
  min-width: fit-content;
  padding: 2px 4px;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
  margin-right: 10px;
`

const IconBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
`

const SuccessPageComponent = (props: any) => (
  <div>
    <Grid container>
      <Grid container>
        <ContainerFlex js="center" w100 al="center">
          <Typography variant="h3" color="White / White">
            ส่งเรื่องเบิกเรียบร้อยแล้ว
          </Typography>
          <Icon
            name="CheckCircleOutline"
            style={{ fontSize: "47px", margin: "12px" }}
            htmlColor={AppColor["Yellow/Primary Text"]}
          />
          <Typography variant="subtitle1" color="White / White">
            เลขทำรายการ : {props.referenceId}
          </Typography>
          <Typography variant="subtitle1" color="White / White">
            {dayjs(props.createdAt).format("DD/MM/YYYY")}
          </Typography>
        </ContainerFlex>
        <Container height="76px" width="inherit">
          <ContainerFlex js="center" al="center">
            <Typography variant="body1" color="Text/Blue">
              เจ้าหน้าที่กำลังตรวจสอบคำขอเบิก
            </Typography>
            <Typography variant="body1" color="Text/Blue">
              เมื่อมีความคืบหน้าระบบจะแจ้งเตือนไปที่อีเมล
            </Typography>
            <Typography variant="body1" color="Text/Blue">
              ของคุณ
            </Typography>
          </ContainerFlex>
        </Container>
        <Typography variant="subtitle1" color="White / White">
          *สามารถเช็คสถานะได้ที่ “ประวัติการเบิก” หรือรอรับแจ้งเตือน บอกผลการยื่นคำขอ
        </Typography>
      </Grid>
    </Grid>
    <div style={{ marginTop: "104px", marginBottom: "40px" }}>
      <Link to="/home" style={{ textDecoration: "none" }}>
        <Button color="primary" width={`100%`}>
          <Icon name="House" style={{ fontSize: "20px", marginRight: "8px" }} />
          กลับหน้าแรก
        </Button>
      </Link>
    </div>
    <ContainerFlex js="start" row w100 al="center">
      <Icon name="DescriptionOutlined" style={{ marginRight: "12px", fontSize: "25px" }} />
      <Typography variant="h3" color="Text/Black2">
        รายละเอียดสวัสดิการที่ยื่นคําขอ
      </Typography>
    </ContainerFlex>
    <SummaryItems>
      <SummaryItem1>
        <ContainerFlex js="space-between" row al="center">
          <ContainerFlex js="" row al="center">
            <IconContainer>
              <IconBox>
                <IconList fullSize name={props.name} iconName={props.icon} iconColor={props.iconColor} />
              </IconBox>
            </IconContainer>
            <Typography variant="body1" color="Text/Black2">
              {props.requestName}
            </Typography>
          </ContainerFlex>
          <Categories color={props.chipColor}>
            <Typography variant="caption" color={props.titleColor}>
              {props.type}
            </Typography>
          </Categories>
        </ContainerFlex>
      </SummaryItem1>

      {props.amount !== undefined && (
        <>
          <Divider />
          <SummaryItem>
            <ContainerFlex js="space-between" row al="center">
              <Typography variant="subtitle1" color="Text/Gray Success">
                ยอดเบิก
              </Typography>
              <Typography variant="body2" color="Text/Black2">
                {toCurrency(props.amount, { minimumFractionDigits: 0 })} บาท
              </Typography>
            </ContainerFlex>
          </SummaryItem>
        </>
      )}
    </SummaryItems>
  </div>
)

const enhancer = compose(
  withFormik({}),
  withHooks((props: any, hooks: any) => {
    const { isRepresent, amount, requestName, type, requester, icon, iconColor, titleColor, chipColor } = props
    return {
      isRepresent,
      amount,
      requestName,
      type,
      requester,
      icon,
      iconColor,
      titleColor,
      chipColor,
      ...props,
    }
  }),
)

const SuccessPage = enhancer(SuccessPageComponent)

export default SuccessPage
