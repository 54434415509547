import { COLOR } from "components/commons/Typography"

export const AppColor = {
  "White / White": "#FFFFFF",
  "Light Blue/Flat Button Background": "#E3F1F9",
  "Gray/Primary Text": "#2B2B2B",
  "Gray/Placeholder": "#606365",
  "Dark Blue/Primary Text": "#003C71",
  "Light Blue/Secondary Text": "#39A0D6",
  "Light Blue/Placeholder": "#8EC9E7",
  "Yellow/Primary Text": "#FFD700",
  "Green/Primary Text": "#009CA6",
  "Green/Button Background": "#E3F4F5",
  "Gray/Flat Button Bg": "#CBD0D1",
  "Gray/Bg": "#F5F6F6",
  "Secondary/Pink": "#EE8B9B",
  "Text/Dark": "#B39E9A",
  "Text/Blue": "#0085CA",
  "Text/Dark Blue": "#003C71",
  "Text/Primary Green": "#009CA6",
  "Text/Primary Pink": "#E31C79",
  "Text/Gray": "#CBD0D1",
  "SecondaryText/Gray": "#454748",
  "Text/Black2": "#2B2B2B",
  "Text/Gray Preview": "#606365",
  "Text/Gray Success": "#A2AAAD",
  "Pink/Bg": "#FCE6F0",
  "Gray/Card Border, Line": "#A2AAAD",
  "Gray/Subtle Light": "#AFB1B6",
  ...COLOR,
}
