import { findKey } from "lodash"
import { isClient } from "common/helper"

const config = {
  production: {
    HOST: "https://app.eclaim.belive.moveplus.dynu.net/",
    API_ENDPOINT: "https://app.eclaim.belive.moveplus.dynu.net/graphql",
  },
  uat: {
    HOST: "https://uat.app.eclaim.belive.ld.moveplus.dynu.net",
    API_ENDPOINT: "https://uat.app.eclaim.belive.ld.moveplus.dynu.net/graphql",
  },
  staging: {
    HOST: "https://dev.app.e-claim.legaldrive.moveplus.dynu.net",
    API_ENDPOINT: "https://dev.app.e-claim.legaldrive.moveplus.dynu.net/graphql",
  },
  e2eStaging: {
    HOST: "https://e2e-dev.app.e-claim.legaldrive.moveplus.dynu.net",
    API_ENDPOINT: "https://e2e-dev.app.e-claim.legaldrive.moveplus.dynu.net/graphql",
  },
  local: {
    HOST: "http://localhost:20703",
    API_ENDPOINT: "http://localhost:20701/graphql",
  },
}

const getEnvByHost = () => (isClient ? findKey(config, { HOST: window.location.origin }) : null)

export const env = process.env.APP_ENV || getEnvByHost() || "local"
// export const env = "staging"

export default config[env]
