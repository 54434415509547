import { compose, withHooks, withStores } from "enhancers"
import { Box, Button, Divider, Grid, IconButton, Modal, Notification } from "components"
import { ClaimCard } from "components/advance/ClaimCard"
import { StepStatus } from "components/advance/StepStatus"
import { UploadDocumentCard } from "components/advance/UploadDocumentCard"
import { Icon } from "components/commons/Icon"
import Typography from "components/commons/Typography"
import { EnumClaimRequestStatus } from "constants/enums/claim-request-status"
import dayjs from "dayjs"
import styled from "styled-components"
import { AppColor } from "theme/app-color"
import { employeeIsHr, gql, paths } from "utils/helper"
import { Field } from "../../claim-detail/form-field"
import { useHistory } from "react-router-dom"

const DetailStatusContainer = styled("div")`
  position: relative;
  background-color: ${AppColor["Dark Blue/Primary Text"]};
  height: auto;
  padding: 16px;
`

const ClaimDetail = styled("div")`
  position: relative;
  background: ${AppColor["White / White"]};
  height: auto;
  padding: 16px;
`

const CloseButton = styled(IconButton)`
  color: ${AppColor["White / White"]};
  padding: 0px;
`

const Title = styled("div")`
  margin-top: 24px;
`

const TopBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DetailBox = styled(Box)`
  margin-top: 16px;
`

const FlexBox = styled(Box)<{ mb?: string }>`
  display: flex;
  margin-bottom: ${(props) => (props.mb ? props.mb : "0px")};
`

const FrontBox = styled(Box)<{ mr?: string }>`
  min-width: 100px;
  margin-right: ${(props) => (props.mr ? props.mr : "0px")};
`

const LightBlueBox = styled(Box)`
  background: ${AppColor["Light Blue/Flat Button Background"]};
  border-radius: 8px;
  padding: 8px;
  margin-top: 16px;
`

const WarningIcon = styled(Icon)`
  font-size: 16px;
  margin-right: 4px;
`

const WarningBox = styled(Box)<{ mt?: string; mb?: string }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 8px;
  height: 32px;
  background-color: ${AppColor["Pink/Bg"]};
  border-radius: 8px;
  margin-top: ${(props) => (props.mt ? props.mt : "0px")};
  margin-bottom: ${(props) => (props.mb ? props.mb : "0px")};
`

const HistoryDetailPage = (props: any) => (
  <>
    <Box style={{ backgroundColor: AppColor["Dark Blue/Primary Text"] }}>
      <Box minHeight={200} minWidth={357} maxWidth={752} overflow="hidden" mx="auto">
        <DetailStatusContainer>
          <TopBox>
            <Typography variant="h2" color="White / White">
              รายละเอียดการเบิก
            </Typography>
            <CloseButton onClick={props.handleClose}>
              <Icon name="Close" style={{ fontSize: "24px" }} />
            </CloseButton>
          </TopBox>
          <DetailBox>
            <FlexBox mb="8px">
              <FrontBox mr="16px">
                <Typography variant="body1" color="White / White">
                  ผู้เบิก
                </Typography>
              </FrontBox>
              <Typography variant="body1" color="White / White">
                {props.createdBy}
              </Typography>
            </FlexBox>
            <FlexBox mb="8px">
              <FrontBox mr="16px">
                <Typography variant="body1" color="White / White">
                  เลขอ้างอิง
                </Typography>
              </FrontBox>
              <Typography variant="body1" color="White / White">
                {props.claimId}
              </Typography>
            </FlexBox>
            <FlexBox mb="8px">
              <FrontBox mr="16px">
                <Typography variant="body1" color="White / White">
                  วันที่เบิก
                </Typography>
              </FrontBox>
              <Typography variant="body1" color="White / White">
                {dayjs(props.createdAt).format("DD/MM/YYYY")}
              </Typography>
            </FlexBox>
            <FlexBox mb="16px" minWidth={325} maxWidth={730} overflow="hidden">
              <FrontBox mr="16px">
                <Typography variant="body1" color="White / White">
                  สถานะ
                </Typography>
              </FrontBox>
              <StepStatus
                claimStatus={props.claimStatus}
                workflows={props.workflows}
                workflowStep={props.workflowStep}
                canceled={props.canceled}
              />
            </FlexBox>
            <FlexBox>
              <FrontBox mr="16px">
                <Typography variant="body1" color="White / White">
                  แก้ไขล่าสุด
                </Typography>
              </FrontBox>
              <Typography variant="body1" color="White / White">
                {dayjs(props.updatedAt).format("DD/MM/YYYY")}
              </Typography>
            </FlexBox>
          </DetailBox>
          {props.claimStatus === EnumClaimRequestStatus.WAITING && !props.withoutCancel && (
            <>
              <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />
              <Button
                onClick={props.onClickCancelClaim}
                width="100%"
                color="primary"
                style={{ border: "none", background: "none", textDecoration: "underline" }}
              >
                <Icon name="DeleteOutlineRounded" style={{ fontSize: "20px", marginRight: "6px" }} />
                ยกเลิกการเบิก
              </Button>
            </>
          )}
          {props.claimStatus === EnumClaimRequestStatus.APPROVED && (
            <LightBlueBox>
              <FlexBox mb="4px">
                <FrontBox mr="8px">
                  <Typography variant="h4" color="Text/Blue">
                    โอนเข้า
                  </Typography>
                </FrontBox>
                <Typography variant="h4" color="Text/Blue">
                  {props.isInsurance ? "บัญชีส่วนตัว" : "บัญชีเงินเดือน"}
                </Typography>
              </FlexBox>
              <FlexBox>
                <FrontBox mr="8px">
                  <Typography variant="subtitle1" color="Text/Gray Preview"></Typography>
                </FrontBox>
                <Typography variant="subtitle1" color="Text/Gray Preview">
                  {props.isInsurance
                    ? "ส่งเอกสารการเคลมให้กับบริษัทประกันเมื่อ " +
                      dayjs(props.transferDate).format("DD/MM/YYYY") +
                      " เงินจะเข้าบัญชีภายใน 15-20 วันทำการ"
                    : "ภายในวันที่ " + dayjs(props.transferDate).format("DD/MM/YYYY")}
                </Typography>
              </FlexBox>
            </LightBlueBox>
          )}
          {props.claimStatus === EnumClaimRequestStatus.REJECTED && (
            <LightBlueBox>
              <FlexBox>
                <FrontBox mr="8px">
                  <Typography variant="h4" color="Text/Blue">
                    เหตุผลที่ปฏิเสธ
                  </Typography>
                </FrontBox>
                <Typography variant="h4" color="Text/Black2">
                  {props.rejectedReason}
                </Typography>
              </FlexBox>
            </LightBlueBox>
          )}
        </DetailStatusContainer>
      </Box>
    </Box>
    <Box minHeight={200} minWidth={357} maxWidth={752} overflow="hidden" mx="auto">
      <ClaimDetail>
        {props.currentUserIsHr && props.isInstead && !props.withoutCancel && (
          <>
            <WarningBox mt="8px" mb="24px">
              <WarningIcon name="Warning" htmlColor={AppColor["Text/Primary Pink"]}></WarningIcon>
              <Typography variant="body2" color="Text/Primary Pink">
                กำลังทำรายการแทนพนักงานคนอื่น
              </Typography>
            </WarningBox>
          </>
        )}
        <Box mt="8px">
          <ClaimCard
            title={props.title}
            type={props.section}
            name={props.name}
            subTitle={props.subTitle}
            approvedRequest={props.approvedCount}
            totalAmount={props.approvedAmount}
            icon={props.icon}
            iconColor={props.iconColor}
            titleColor={props.titleColor}
            chipColor={props.chipColor}
            fullSize
            notLink
          />
        </Box>
        <Title>
          <Typography variant="h3">ตรวจสอบรายละเอียด</Typography>
        </Title>

        <Grid container style={{ paddingTop: 16 }}>
          <Grid item xs={12} key="select-employee">
            <Typography variant="body1" color="Text/Gray Preview">
              ผู้ขอเบิก
            </Typography>
            <Field isPreview={true} component="SELECT" label="" name="requester" value={props.requesterValues} />
          </Grid>
          {props.inputs?.map((input: any, ind: number) => (
            <Grid item xs={12} key={`${input.name} ${ind}`} style={{ paddingTop: 16 }}>
              <Typography variant="body1" color="Text/Gray Preview">
                {input.title}
              </Typography>
              <Field
                isPreview={true}
                value={input.value}
                component={input.type}
                label=""
                name={input.name}
                options={input.options}
                unit={input.unit}
              />
            </Grid>
          ))}
        </Grid>
        {props.documents.length > 0 && (
          <UploadDocumentCard
            documents={props.documents}
            onChange={props.handleFilesChange}
            canDelete={false}
            isPreview={true}
          />
        )}
      </ClaimDetail>
    </Box>
  </>
)

const API = {
  GET_CLAIM_REQUEST_CONFIGS: gql`
    query GET_CLAIM_REQUEST_CONFIGS($employeeId: String!) {
      claimRequestConfigs(employeeId: $employeeId) {
        usage
      }
    }
  `,
  GET_HISTORY_DETAIL: gql`
    query GET_HISTORY_DETAIL($id: String!) {
      claimRequestHistoryDetail(id: $id) {
        workflowSeq
        id
        type
        status
        config
        info
        employeeId
        employee
        createdAt
        updatedAt
        createdBy
        referenceId
        rejectedReason
        transferDate
        canceled
        currentWorkflow
      }
    }
  `,
  CANCEL_REQUEST: gql`
    mutation CANCEL_REQUEST($id: String!) {
      cancelClaimRequest(id: $id) {
        id
        status
      }
    }
  `,
}

const enhancer = compose(
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
  })),
  withHooks((props: any, hooks: any) => {
    const { useParams, useQuery, useLazyQuery, useState, useEffect, useMutation, useMemo, useCallback } = hooks
    const { currentUserInfo, withoutCancel } = props
    const currentUser = useMemo(() => {
      return currentUserInfo.employee
    }, [currentUserInfo])
    const { id } = useParams()
    const { data, refetch } = useQuery(API.GET_HISTORY_DETAIL, {
      variables: { id },
    })
    const [usage, setUsage] = useState()
    const [refetchConfig] = useLazyQuery(API.GET_CLAIM_REQUEST_CONFIGS, {
      onCompleted: (dataConfig: any) => {
        const current = dataConfig.claimRequestConfigs.usage.find(
          (res: any) => res.name === data?.claimRequestHistoryDetail.info.name,
        )
        setUsage(current)
      },
      fetchPolicy: "network-only",
    })
    const [cancelRequest] = useMutation(API.CANCEL_REQUEST)

    const employee = useMemo(() => data?.claimRequestHistoryDetail.employee, [data])

    const handleClose = useCallback(() => {
      if (withoutCancel) {
        paths.dashboardPath().push()
      } else {
        paths.historyPath().push()
      }
    }, [withoutCancel])

    const requestRejected = useMemo(
      () =>
        data?.claimRequestHistoryDetail.workflowSeq.filter(
          (flow: any) => flow.status === EnumClaimRequestStatus.REJECTED,
        ).length > 0,
      [data],
    )

    const hasFile = useMemo(() => data?.claimRequestHistoryDetail.info.documents.filter((doc: any) => doc.file) > 0, [
      data,
    ])

    const currentUserIsHr = useMemo(() => employeeIsHr(currentUser.role), [currentUser])

    const isInstead = useMemo(
      () => data?.claimRequestHistoryDetail.employee.id !== data?.claimRequestHistoryDetail.createdBy.id,
      [data],
    )

    const requestAmount = useCallback((history: any) => {
      let amount = 0
      history.info.inputs.forEach((input: any) => {
        if (input.name === history.info.amountFieldName) {
          amount = input.value
        }
      })
      return amount
    }, [])

    const handleCancelClaim = useCallback(
      async (confirmProps: any) => {
        try {
          await cancelRequest({ variables: { id } })
          confirmProps.close()
          paths.historyPath().push()
          Notification.success("ยกเลิกคำร้องแล้ว")
        } catch (e) {}
      },
      [id, cancelRequest],
    )

    const onClickCancelClaim = useCallback(() => {
      Modal.confirm({
        className: "deleteConFirmModal",
        title: "",
        children: (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box mb="16px">
              <Typography variant="h3" color="Text/Black2">
                ต้องการยกเลิกการเบิกสวัสดิการ ?
              </Typography>
            </Box>
            <Box mb="4px">
              <Typography variant="body1" color="Text/Gray Preview">
                ยกเลิกการเบิก
              </Typography>
            </Box>
            <Box mb="16px">
              <Typography variant="h6" color="Text/Blue">
                {data?.claimRequestHistoryDetail.info.claimCard.title +
                  " " +
                  requestAmount(data?.claimRequestHistoryDetail) +
                  " บาท"}
              </Typography>
            </Box>
          </Box>
        ),
        okButtonLabel: "ยืนยัน",
        okButtonVariant: "contained",
        okButtonColor: "primary",
        cancelButtonLabel: "ปิดหน้าต่างนี้",
        cancelButtonVariant: "outlined",
        cancelButtonColor: "primary",
        buttonWidth: 147,
        onOk: handleCancelClaim,
      })
    }, [handleCancelClaim, data, requestAmount])

    const isInsurance = useMemo(
      () =>
        data?.claimRequestHistoryDetail.info.section === "ประกันกลุ่ม" ||
        data?.claimRequestHistoryDetail.info.section === "ประกันสังคม",
      [data],
    )

    const nextActionWorkflow = useMemo(() => {
      const workflows = data?.claimRequestHistoryDetail.workflowSeq
      const currentWorkflow = workflows?.find(
        (flow: any) => flow.seq === data?.claimRequestHistoryDetail.currentWorkflow + 1,
      )
      return currentWorkflow
    }, [data])

    useEffect(() => {
      const claimUser = data?.claimRequestHistoryDetail.employee
      if (claimUser) {
        refetchConfig({
          variables: { employeeId: claimUser.id },
        })
      }
    }, [data, refetchConfig])
    const [workflowStep, setWorkflowStep] = useState(0)

    useEffect(() => {
      refetch()
      if (data?.claimRequestHistoryDetail.workflowSeq) {
        const workflows = data?.claimRequestHistoryDetail.workflowSeq
        const last = workflows[workflows.length - 1]
        if (nextActionWorkflow) {
          const nextActionIndex = workflows.findIndex(
            (wf: any) => JSON.stringify(wf) === JSON.stringify(nextActionWorkflow),
          )
          setWorkflowStep(nextActionIndex)
        } else if (last) {
          setWorkflowStep(workflows.length)
        }
      }
    }, [data, nextActionWorkflow, refetch])

    return {
      handleClose,
      createdAt: data?.claimRequestHistoryDetail.createdAt,
      updatedAt: data?.claimRequestHistoryDetail.updatedAt,
      inputs: data?.claimRequestHistoryDetail.info.inputs,
      documents: data?.claimRequestHistoryDetail.info.documents.filter((doc: any) => doc.file) || [],
      claimId: data?.claimRequestHistoryDetail.referenceId,
      createdBy:
        data?.claimRequestHistoryDetail.createdBy.firstName + " " + data?.claimRequestHistoryDetail.createdBy.lastName,
      requesterValues: employee ? employee.firstName + " " + employee.lastName : undefined,

      workflows: data?.claimRequestHistoryDetail.workflowSeq || [],
      workflowStep: requestRejected ? (workflowStep - 1 < 0 ? 0 : workflowStep - 1) : workflowStep,
      hasFile,
      title: data?.claimRequestHistoryDetail.info.claimCard.title,
      subTitle: data?.claimRequestHistoryDetail.info.claimCard.subTitle,
      icon: data?.claimRequestHistoryDetail.info.claimCard.icon,
      iconColor: data?.claimRequestHistoryDetail.info.claimCard.iconColor,
      titleColor: data?.claimRequestHistoryDetail.info.claimCard.titleColor,
      chipColor: data?.claimRequestHistoryDetail.info.claimCard.chipColor,
      section: data?.claimRequestHistoryDetail.info.section,
      name: data?.claimRequestHistoryDetail.info.name,
      approvedAmount: usage?.approvedAmount,
      approvedCount: usage?.approvedCount,
      claimStatus: data?.claimRequestHistoryDetail.status,
      transferDate: data?.claimRequestHistoryDetail.transferDate || null,
      rejectedReason: data?.claimRequestHistoryDetail.rejectedReason || null,
      onClickCancelClaim,
      currentUserIsHr,
      isInstead,
      isInsurance,
      canceled: data?.claimRequestHistoryDetail.canceled,
      withoutCancel,
    }
  }),
)

export default enhancer(HistoryDetailPage)
