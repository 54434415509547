const overrides = {
  MuiDataGrid: {
    root: {
      fontFamily: [
        "Kanit",
        "Inter",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  },
  MuiDivider: {
    root: {
      backgroundColor: "#B39E9A",
    },
  },
  MuiDialog: {
    paper: {
      padding: 24,
    },
  },
  MuiDialogTitle: {
    root: {
      padding: 0,
      "& .MuiTypography-root": {
        lineHeight: 1.75,
      },
    },
  },
  MuiDialogContent: {
    root: {
      margin: 0,
      padding: 0,
      paddingTop: 16,
      "& .MuiTypography-root": {
        margin: 0,
        lineHeight: 1.25,
      },
    },
  },
  MuiDialogActions: {
    root: {
      padding: 0,
      marginTop: 16,
    },
  },
  MuiCard: {
    root: {
      borderRadius: "6px",
      boxShadow: "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px",
    },
  },
  MuiButton: {
    root: {
      height: 44,
      borderRadius: 12,
      minWidth: 56,
      fontSize: 14,
      fontWeight: 500,
    },
    contained: {
      backgroundColor: "#white",
      border: "1px solid #E0E0E0",
      color: "#212121",

      "& path": {
        fill: "#212121",
      },
    },
  },
  MuiAutocomplete: {
    root: {
      "& .MuiAutocomplete-inputRoot": {
        padding: 0,
        paddingLeft: "65px",
        paddingRight: "14px",
      },
    },
  },
  MuiFormControl: {
    root: {
      width: "100%",
    },
  },
  MuiTextField: {
    root: {
      // "& .MuiFormLabel-root": {
      //   color: "#CCBEBC",
      // },
      "& .MuiInputBase-root": {
        borderRadius: 8,
        color: "#2B2B2B",
        fontSize: 16,
        fontWeight: 500,
        height: "48px",
      },
      "& .MuiInputBase-root.Mui-disabled": {
        color: "#606365",
        background: "#E3E9EF",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#A2AAAD",
        },
      },
      "& .MuiOutlinedInput-input": {
        maxWidth: "1200px",
      },
    },
  },
  MuiCardHeader: {
    action: {
      marginTop: "-4px",
      marginRight: "-4px",
    },
  },
  MuiPickersDay: {
    day: {
      fontWeight: "300",
    },
  },
  MuiPickersYear: {
    root: {
      height: "64px",
    },
  },
  MuiPickersCalendar: {
    transitionContainer: {
      marginTop: "6px",
    },
  },
  MuiPickersCalendarHeader: {
    iconButton: {
      backgroundColor: "transparent",
      "& > *": {
        backgroundColor: "transparent",
      },
    },
    switchHeader: {
      marginTop: "2px",
      marginBottom: "4px",
    },
  },
  MuiPickersClock: {
    container: {
      margin: `32px 0 4px`,
    },
  },
  MuiPickersClockNumber: {
    clockNumber: {
      left: `calc(50% - 16px)`,
      width: "32px",
      height: "32px",
    },
  },
  MuiPickerDTHeader: {
    dateHeader: {
      "& h4": {
        fontSize: "2.125rem",
        fontWeight: 400,
      },
    },
    timeHeader: {
      "& h3": {
        fontSize: "3rem",
        fontWeight: 400,
      },
    },
  },
  MuiPickersTimePicker: {
    hourMinuteLabel: {
      "& h2": {
        fontSize: "3.75rem",
        fontWeight: 300,
      },
    },
  },
  MuiPickersToolbar: {
    toolbar: {
      "& h4": {
        fontSize: "2.125rem",
        fontWeight: 400,
      },
    },
  },
  MuiChip: {
    root: {
      borderRadius: "6px",
    },
  },
  MuiFormLabel: {
    root: {
      color: "#2B2B2B",
      fontSize: 16,
      fontWeight: 500,
    },
    asterisk: {
      color: "#F34336",
      "&$error": {
        color: "#F34336",
      },
    },
  },
  MuiFormGroup: {
    root: {
      color: "#805D57",
    },
  },
}

export default overrides
