import { Dialog as MuiDialog, DialogActions, DialogContent, TextareaAutosize } from "@material-ui/core"
import { Box, Button } from "components"
import Typography from "components/commons/Typography"
import { isEmpty } from "lodash"
import { useCallback, useState } from "react"
import styled from "styled-components"
import { toCurrency } from "utils/helper"

const Dialog = styled(MuiDialog)`
  .MuiDialog-paperScrollPaper {
    padding: 0;
    margin: 1vw;
    min-width: 343px;
    overflow: hidden;
  }
`

const ModalContainer = styled(DialogContent)`
  /*max-width: 500px;
  min-width: 340px;*/
  height: 100%;
  /*min-height: 200px;*/
  padding: 16px;
`

const ContentContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const TitleContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0px auto;
  margin-bottom: 16px;
`

const TextAreaLabelContainer = styled("div")`
  width: 100%;
`

const ActionContainer = styled(Box)`
  display: flex;
  width: 100%;
  padding: 0px 16px 16px 16px;
`

const ActionButton = styled(Button)`
  width: 100%;
`

interface ApproveModalProps {
  isOpen: boolean
  title: string
  onClose: () => void
  onConfirm: (date: string) => void
  amount: string
}

export const RejectModal = (props: ApproveModalProps) => {
  const { isOpen, onClose, title, onConfirm, amount } = props

  const [value, setValue] = useState("")

  const handleChangeValue = useCallback((value: any) => {
    setValue(value)
  }, [])

  const handleClose = useCallback(() => {
    onClose()
    setValue("")
  }, [onClose])

  const handleConfirm = useCallback(() => {
    if (!isEmpty(value)) {
      onConfirm(value)
      handleClose()
    }
  }, [onConfirm, handleClose, value])

  return (
    <Dialog open={isOpen} closeAfterTransition>
      <ModalContainer>
        <ContentContainer>
          <TitleContainer>
            <Typography variant="h3" color="Text/Black2">
              ต้องการปฏิเสธการเบิกสวัสดิการ ?
            </Typography>
          </TitleContainer>
          <Box mb="4px">
            <Typography variant="body1" color="Text/Gray Preview">
              ไม่อนุมัติการเบิก
            </Typography>
          </Box>
          <Box mb="16px">
            <Typography variant="h6" color="Text/Blue">
              {title + " " + amount + " "}
              บาท
            </Typography>
          </Box>
          <TextAreaLabelContainer>
            <Typography variant="body1" color="Text/Black2">
              โปรดระบุเหตุผล*
            </Typography>
          </TextAreaLabelContainer>
          <TextareaAutosize
            style={{
              width: "calc(100% - 24px)",
              padding: "12px",
              borderRadius: 8,
              resize: "none",
              marginTop: 8,
            }}
            rowsMax={4}
            rowsMin={4}
            maxLength={250}
            value={value}
            placeholder="โปรดระบุ"
            onChange={(e) => handleChangeValue(e.target.value)}
          />
        </ContentContainer>
      </ModalContainer>
      <DialogActions>
        <ActionContainer>
          <ActionButton variant="outlined" color="primary" onClick={handleClose} mr="16px">
            ปิดหน้าต่างนี้
          </ActionButton>
          <ActionButton variant="contained" color="primary" onClick={handleConfirm}>
            ยืนยัน
          </ActionButton>
        </ActionContainer>
      </DialogActions>
    </Dialog>
  )
}
