import paths from "routes/paths";

import { RouteType } from "types/routes";

// Menu Icon
import { ReactComponent as AdminIcon } from "vendor/menu_icon/admin.svg";

const MAIN_MENU: RouteType[] = [
  // {
  //   id: "ผู้ดูแลระบบ",
  //   path: paths.adminsPath().toString(),
  //   header: "เมนู",
  //   icon: <AdminIcon />,
  // },
];

export default MAIN_MENU;
