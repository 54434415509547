import { Field, Box } from "components"
import Typography from "components/commons/Typography"
import { INPUT_TYPE } from "constants/enums/input-type"
import { mappingComponent } from "."

import { PreviewField } from "./preview"

export interface CustomFieldProps {
  component: keyof typeof INPUT_TYPE
  name: string
  label: string
  options?: any[]
  isPreview?: boolean
  value?: string
  disabled?: boolean
  disableClearable?: boolean
  unit?: string
}

export const CustomField = (props: CustomFieldProps) => {
  const {
    label,
    name,
    isPreview,
    value,
    component,
    options = [],
    disabled = false,
    disableClearable = true,
    unit,
  } = props

  const components = mappingComponent(INPUT_TYPE[component])

  if (isPreview) return <PreviewField previewProps={{ label, value, component, unit }} />
  return (
    components && (
      <>
        <Box display={unit ? "flex" : ""} justifyContent={unit ? "space-between" : ""}>
          <Field
            fast
            options={options}
            component={components}
            name={name}
            label={label}
            disabled={disabled}
            inputProps={{ style: { textAlign: unit ? "end" : "" } }}
            fullWidth
            freeSolo={name === "province_departure_place" || name === "province_arrival_place"}
            disableClearable={disableClearable}
          />
          {unit && (
            <Box display="flex" alignItems="center" justifyContent="flex-end" ml={4} minWidth="27px">
              <Typography variant="h4" color="Text/Black2">
                {unit}
              </Typography>
            </Box>
          )}
        </Box>
      </>
    )
  )
}
