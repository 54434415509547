import { Box, Button, Divider, Grid, Hidden, Modal } from "components"
import { ClaimCard } from "components/advance/ClaimCard"
import ClaimConditions from "components/advance/ClaimConditions"
import ClaimRemarks from "components/advance/ClaimRemarks"
import { RequiredDocumentCard } from "components/advance/RequiredDocumentCard"
import { DocumentConfig, UploadDocumentCard } from "components/advance/UploadDocumentCard"
import { Icon } from "components/commons/Icon"
import Typography from "components/commons/Typography"
import { INPUT_TYPE } from "constants/enums/input-type"
import { OptionValue } from "constants/enums/option-value"
import { Option } from "constants/interface/options"
import dayjs from "dayjs"
import { compose, withFormik, withHooks, withStores } from "enhancers"

import { PageContent } from "layouts"
import { get, isEmpty, isNil, uniqBy } from "lodash"
import styled from "styled-components"
import { AppColor } from "theme/app-color"
import { employeeIsHr, gql, isJson, paths, toCurrency } from "utils/helper"
import { AutoCalMethods } from "./autoCalMethods"
import { Field } from "./form-field"
import SuccessPage from "./PageSuccess"

const CustomIconList = styled(Icon)`
  font-size: 20px;
  margin-left: 8px;
`

const WarningIcon = styled(Icon)`
  font-size: 16px;
  margin-right: 4px;
`

const WarningBox = styled(Box)<{ mt?: string; mb?: string }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 8px;
  height: 32px;
  background-color: ${AppColor["Pink/Bg"]};
  border-radius: 8px;
  margin-top: ${(props) => (props.mt ? props.mt : "0px")};
  margin-bottom: ${(props) => (props.mb ? props.mb : "0px")};
`

const List = styled.ul`
  margin: 0px 0px 0px -16px;
`

const ClaimDetailPage = (props: any) => (
  <>
    <PageContent
      title={props.titlePage}
      titleCentered
      onBack={props.handleClickBack}
      titleDark={false}
      blueContainerHeight={props.step === 4 ? (props.hrInstead && props.currentUserIsHr ? "378px" : "340px") : "94px"}
      childrenMarginTop={props.step === 4 ? (props.hrInstead && props.currentUserIsHr ? "-369px" : "-331px") : "-85px"}
      childrenPadding={props.remark?.length === 0 ? "16px 16px 0px 16px" : ""}
    >
      {props.hrInstead && props.currentUserIsHr && props.isPreview && (
        <>
          <WarningBox mb={props.step === 4 ? "14px" : "16px"}>
            <WarningIcon name="Warning" htmlColor={AppColor["Text/Primary Pink"]}></WarningIcon>
            <Typography variant="body2" color="Text/Black2">
              กำลังทำรายการแทนพนักงานคนอื่น
            </Typography>
          </WarningBox>
        </>
      )}
      {props.step !== 4 && (
        <>
          <ClaimCard
            title={props.claimCard.title}
            type={props.section}
            name={props.name}
            subTitle={props.claimCard.subTitle}
            approvedRequest={props.approvedCount}
            totalAmount={props.approvedAmount}
            icon={props.claimCard.icon}
            iconColor={props.claimCard.iconColor}
            titleColor={props.claimCard.titleColor}
            chipColor={props.claimCard.chipColor}
            fullSize
          />
          {!props.isPreview && (
            <>
              <ClaimConditions conditions={props.conditions} />
              <RequiredDocumentCard documents={props.documents} />
              <Hidden when={props.remark?.length === 0}>
                <Divider style={{ margin: "32px 0px 4px 0px", color: AppColor["Text/Black"] }} />
              </Hidden>
              <ClaimRemarks remarks={props.remark} step={props.step} />
            </>
          )}
          {props.step === 1 && (
            <Button
              onClick={props.handleClickClaim}
              width="100%"
              color="primary"
              variant="outlined"
              style={{ marginBottom: 24 }}
            >
              เบิกสวัสดิการนี้
              <CustomIconList name="ChevronRight" />
            </Button>
          )}
        </>
      )}
      {props.step === 4 && (
        <>
          <SuccessPage
            isRepresent={props.isRepresent}
            requester={props.requesterFullName}
            requestName={props.claimCard.title}
            type={props.section}
            amount={props.amount}
            referenceId={props.requestResponse.referenceId}
            createdDate={props.requestResponse.createdAt}
            icon={props.claimCard.icon}
            iconColor={props.claimCard.iconColor}
            titleColor={props.claimCard.titleColor}
            chipColor={props.claimCard.chipColor}
            name={props.name}
          />
        </>
      )}
    </PageContent>
    {props.step !== 1 && props.step !== 4 && (
      <PageContent
        title=""
        titleCentered
        pageBorderTop={props.isPreview ? "" : `1px solid ${AppColor["Dark Blue/Primary Text"]}`}
        childrenPadding={props.isPreview ? "0px 16px 16px 16px" : ""}
      >
        <>
          <Box
            mt={
              props.isPreview
                ? props.hrInstead && props.currentUserIsHr && props.remark?.length === 0
                  ? "16px"
                  : props.remark?.length === 0
                  ? "16px"
                  : "0px"
                : "8px"
            }
          >
            <Typography variant="h3" color={props.isPreview ? "Text/Primary Green" : "Text/Black2"}>
              {props.isPreview ? "ตรวจสอบรายละเอียด" : "กรอกรายละเอียดการเบิก"}
            </Typography>
          </Box>
          {props.hrInstead && props.currentUserIsHr && !props.isPreview && (
            <>
              <WarningBox mt="16px">
                <WarningIcon name="Warning" htmlColor={AppColor["Text/Primary Pink"]}></WarningIcon>
                <Typography variant="body2" color="Text/Black2">
                  กำลังทำรายการแทนพนักงานคนอื่น
                </Typography>
              </WarningBox>
            </>
          )}
          <Grid container spacing={0} style={{ paddingTop: 16, marginBottom: "40px" }}>
            <Grid item xs={12} key="select-employee">
              <Typography variant="body1" color={props.isPreview ? "Text/Gray Preview" : "Text/Black2"}>
                ผู้ขอเบิก
              </Typography>
              <Box mt={props.isPreview ? "0px" : "8px"}>
                <Field
                  isPreview={props.isPreview}
                  component="SELECT"
                  label=""
                  name="requester"
                  options={props.employeeOptions}
                  value={props.requesterFullName}
                  disabled={!props.currentUserIsHr}
                  disableClearable
                />
              </Box>
            </Grid>
            {props.inputs.map((input: any, ind: number) => (
              <>
                <Grid item xs={12} key={`${input.name} ${ind}`} style={{ paddingTop: 16 }}>
                  <Typography variant="body1" color={props.isPreview ? "Text/Gray Preview" : "Text/Black2"}>
                    {input.title}
                  </Typography>
                  <Box mt={props.isPreview ? "0px" : "8px"}>
                    <Field
                      isPreview={props.isPreview}
                      value={input.value}
                      component={input.type}
                      label=""
                      name={input.name}
                      disabled={props.currentUserIsHr ? false : input.disabled}
                      options={props.getOptions(input.options, input.name)}
                      unit={input.unit}
                    />
                  </Box>
                </Grid>
                {props.hasIsOther(input.type, input.name) && (
                  <Grid item xs={12} key={`${input.name} ${ind}_is_other`} style={{ paddingTop: 16 }}>
                    <Typography variant="body1" color={props.isPreview ? "Text/Gray Preview" : "Text/Black2"}>
                      {`กรอก ${input.title} อื่นๆ`}
                    </Typography>
                    <Box mt={props.isPreview ? "0px" : "8px"}>
                      <Field component={INPUT_TYPE.TEXT} label="" name={input.name + "_is_other"} />
                    </Box>
                  </Grid>
                )}
              </>
            ))}
          </Grid>

          {props.showUploadFile && (
            <UploadDocumentCard
              documents={props.documents}
              onChange={props.handleFilesChange}
              canDelete={!props.isPreview}
              isPreview={props.isPreview}
            />
          )}
          {props.isPreview ? (
            <Button onClick={props.handleClickSendRequest} style={{ marginBottom: 8 }} width={`100%`} color="primary">
              ยื่นคำขอ
            </Button>
          ) : (
            <Button
              onClick={props.handleSubmit}
              style={{ marginBottom: 8 }}
              width={`100%`}
              color="primary"
              variant="outlined"
            >
              ถัดไป
              <CustomIconList name="ChevronRight" />
            </Button>
          )}
        </>
      </PageContent>
    )}
  </>
)

const API = {
  GET_CLAIM_REQUEST_CONFIGS: gql`
    query GET_CLAIM_REQUEST_CONFIGS($employeeId: String!) {
      claimRequestConfigs(employeeId: $employeeId) {
        usage
      }
    }
  `,
  CREATE_CLAIM_REQUEST: gql`
    mutation CREATE_CLAIM_REQUEST(
      $type: String!
      $config: JSON!
      $info: JSON!
      $employeeId: String!
      $createdBy: String!
    ) {
      createClaimRequest(
        input: { type: $type, config: $config, info: $info, employeeId: $employeeId, createdBy: $createdBy }
      ) {
        id
        referenceId
        createdAt
      }
    }
  `,
  GET_EMPLOYEES: gql`
    query GET_EMPLOYEES {
      getEmployees {
        id
        employeeCode
        firstName
        lastName
        role
        grade
        employmentStartDate
        employmentType
        probationPeriod
        probationStatus
        functionalDesignation
      }
    }
  `,
  VALIDATE_REQUEST: gql`
    mutation VALIDATE_REQUEST($info: JSON!, $employeeId: String!) {
      validateClaim(inputs: { info: $info, employeeId: $employeeId }) {
        hasErrorMessage
      }
    }
  `,
}

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({}),
  }),
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
  })),
  withHooks((props: any, hooks: any) => {
    const { setFieldValue, values, handleSubmit, currentUserInfo, dirty } = props
    const {
      useHandleSubmit,
      useState,
      useMemo,
      useMutation,
      useQuery,
      useLazyQuery,
      useCallback,
      useEffect,
      usePrevious,
    } = hooks
    const selectedRequestStorage = useMemo(() => JSON.parse(localStorage.getItem("selected_request") || ""), [])
    const [selectedRequest, setSelectedRequest] = useState(selectedRequestStorage)
    const currentUser = useMemo(() => {
      return currentUserInfo.employee
    }, [currentUserInfo])
    const [refetchConfig, { data: configs }] = useLazyQuery(API.GET_CLAIM_REQUEST_CONFIGS, {
      onCompleted: (data: any) => {
        const current = data.claimRequestConfigs.usage.find((res: any) => res.name === selectedRequest.name)
        setSelectedRequest({
          ...selectedRequest,
          approvedAmount: current.approvedAmount,
          approvedCount: current.approvedCount,
        })
      },
      fetchPolicy: "network-only",
    })
    const [validateRequest] = useMutation(API.VALIDATE_REQUEST)

    const master = useMemo(() => {
      return JSON.parse(localStorage.getItem("master") || "")
    }, [])

    const workflowConfigs = useMemo(() => JSON.parse(localStorage.getItem("workflow") || ""), [])

    const {
      claimCard,
      conditions,
      inputs,
      documents,
      remark,
      amountFieldName,
      approvedCount,
      approvedAmount,
      section,
      name,
    } = selectedRequest
    const initialDocumentWithValue: DocumentConfig[] = useMemo(
      () => documents.map((doc: any) => ({ ...doc, file: "" })),
      [documents],
    )

    const { data: employeesRes } = useQuery(API.GET_EMPLOYEES)

    const [createClaimRequest] = useMutation(API.CREATE_CLAIM_REQUEST)

    const [inputsWithValue, setInputsWithValue] = useState([])
    const [documentWithValue, setDocumentWithValue] = useState(initialDocumentWithValue)
    const [isPreview, setIsPreview] = useState(false)
    const [requester, setRequester] = useState(currentUser)

    const [stepPage, setStepPage] = useState(1)
    const [requestResponse, setRequestResponse] = useState()

    const getStringValue = useCallback((type: INPUT_TYPE, formName: string, values: any) => {
      const value = values[formName]
      if (!value) return undefined
      switch (type) {
        case INPUT_TYPE.TIME_PICKER:
          return dayjs(value).format("HH:mm")
        case INPUT_TYPE.DATE:
          return dayjs(value).format("DD/MM/YYYY")
        case INPUT_TYPE.NUMBER:
        case INPUT_TYPE.CURRENCY:
          return toCurrency(value, { minimumFractionDigits: 0 })
        case INPUT_TYPE.SELECT:
          return value === OptionValue.IS_OTHER ? values[formName + "_is_other"] : values[formName]
        default:
          return value.toString()
      }
    }, [])

    const userWorkflow = useMemo(() => {
      return workflowConfigs.find((workflow: any) => workflow.role === requester.role)
    }, [workflowConfigs, requester])

    useHandleSubmit(
      async (values: any) => {
        // console.log(values)
        const initInputValues = inputs.map((input: any) => ({
          ...input,
          value: getStringValue(input.type, input.name, values),
        }))
        const info = {
          ...selectedRequest,
          inputs: initInputValues,
          documents: documentWithValue,
          workflow: userWorkflow,
        }
        console.log(documentWithValue)

        const isValid = await handleValidateRequest(info, values.requester.id)
        if (isValid) {
          setInputsWithValue(initInputValues)
          setRequester(values.requester)
          setIsPreview(true)
          setStepPage(3)
        }
      },
      [inputs, selectedRequest, documentWithValue, userWorkflow],
    )

    const handleValidateRequest = useCallback(
      async (info: any, requesterId: string) => {
        try {
          await validateRequest({
            variables: { info, employeeId: requesterId },
          })
        } catch (error: any) {
          if (isJson(error.message)) {
            Modal.alert({
              className: "deleteConFirmModal",
              title: "",
              children: (
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                  <Box mb="16px">
                    <Icon fontSize="large" htmlColor={AppColor["Text/Primary Pink"]} name="Warning"></Icon>
                  </Box>
                  <Box mb="16px">
                    <List>
                      {JSON.parse(error.message).map((mes: any, index: number) => (
                        <li key={index}>
                          <Typography variant="body1" color="Text/Black2">
                            {mes}
                          </Typography>
                        </li>
                      ))}
                    </List>
                  </Box>
                </Box>
              ),
              okButtonLabel: "ตกลง",
              okButtonVariant: "contained",
              okButtonColor: "primary",
              buttonWidth: "100%",
            })
          }
          return false
        }
        return true
      },
      [validateRequest, requester],
    )

    const handleClickSendRequest = useCallback(async () => {
      try {
        const info = {
          ...selectedRequest,
          inputs: inputsWithValue,
          documents: documentWithValue,
          workflow: userWorkflow,
        }
        const res = await createClaimRequest(
          {
            variables: {
              type: selectedRequest.name,
              config: selectedRequest,
              info,
              employeeId: requester.id,
              createdBy: currentUser.id,
            },
          },
          {},
        )

        setRequestResponse(res.data.createClaimRequest)
        setStepPage(4)
      } catch (error: any) {
        if (isJson(error.message)) {
          Modal.alert({
            className: "deleteConFirmModal",
            title: "",
            children: (
              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Box mb="16px">
                  <Icon fontSize="large" htmlColor={AppColor["Text/Primary Pink"]} name="Warning"></Icon>
                </Box>
                <Box mb="16px">
                  <List>
                    {JSON.parse(error.message).map((mes: any, index: number) => (
                      <li key={index}>
                        <Typography variant="body1" color="Text/Black2">
                          {mes}
                        </Typography>
                      </li>
                    ))}
                  </List>
                </Box>
              </Box>
            ),
            okButtonLabel: "ตกลง",
            okButtonVariant: "contained",
            okButtonColor: "primary",
            buttonWidth: "100%",
          })
        }
      }
    }, [inputsWithValue, documentWithValue, selectedRequest, createClaimRequest, currentUser, requester, userWorkflow])

    const handleFilesChange = useCallback((documents: DocumentConfig[]) => {
      console.log(documents)

      setDocumentWithValue(documents.sort((a, b) => a.seq - b.seq))
    }, [])

    const handleClickBack = useCallback(() => {
      switch (stepPage) {
        case 1:
          paths.listPath().push()
          break
        case 4:
          paths.landingPath().push()
          break
        case 3:
          setIsPreview(false)
          setStepPage(stepPage - 1)
          break
        case 2:
          setStepPage(stepPage - 1)
          break
        default:
          setStepPage(stepPage - 1)
          break
      }
    }, [stepPage])

    const employeeOptions = useMemo(
      () =>
        employeesRes?.getEmployees
          .filter((emp: any) => !employeeIsHr(emp.role) || emp.employeeCode === currentUser.employeeCode)
          .map((employee: any) => ({
            label: `${employee.firstName} ${employee.lastName}`,
            value: employee,
          })),
      [employeesRes, currentUser],
    )

    const currentUserIsHr = useMemo(() => employeeIsHr(currentUser.role), [currentUser])

    const isRepresent = useMemo(() => {
      return requester?.id !== currentUser.id
    }, [requester, currentUser])

    const showUploadFile = useMemo(() => {
      console.log(documentWithValue.filter((doc: any) => doc.file).length > 0)

      if (isPreview) return documentWithValue.filter((doc: any) => doc.file).length > 0
      return true
    }, [isPreview, documentWithValue])

    const getOptions = useCallback(
      (path: string, name?: string) => {
        if (path && !isEmpty(path)) {
          if (name === "district_departure_place" || name === "district_distances") {
            const provinceData = uniqBy(
              get(master, path.split(".")[1]).map((option: any) => {
                const [province, district] = option.label.split(" » ")
                return { label: district, value: district }
              }),
              "label",
            )
            return provinceData
          } else return get(master, path.split(".")[1]) as Option[]
        }
        return []
      },
      [master],
    )

    const hasIsOther = useCallback(
      (type: INPUT_TYPE, name: string) => {
        if (isPreview) return false
        if (type !== INPUT_TYPE.SELECT) return false
        return values[name] === OptionValue.IS_OTHER
      },
      [isPreview, values],
    )

    const handleClickClaim = useCallback(() => {
      setStepPage(2)
    }, [setStepPage])

    const hrInstead = useMemo(() => !(currentUser.id === requester.id), [currentUser, requester])

    const autoCalConfigs = useMemo(() => {
      const fieldWithAutoCalMethods = inputs
        .map((input: any) => {
          const methodKey = `${name}__${input.name}`
          // console.log(methodKey);
          console.log(AutoCalMethods[methodKey as keyof typeof AutoCalMethods])

          return { ...input, autoCalMethod: AutoCalMethods[methodKey as keyof typeof AutoCalMethods] }
        })
        .filter((input: any) => input.autoCal && !!input.autoCalMethod)
      return fieldWithAutoCalMethods
    }, [inputs, name])

    const previousValues = usePrevious(values)

    useEffect(() => {
      if (values.requester) {
        console.log(values.requester)

        autoCalConfigs.forEach(({ name, autoCalMethod }: any) => {
          const value = autoCalMethod({ values })
          const prev = previousValues && previousValues[name]
          const current = values && values[name]
          if (current === prev) {
            // console.log(previousValues, values)
            setFieldValue(name, value)
          }
        })
      }
    }, [autoCalConfigs, values, setFieldValue])

    useEffect(() => {
      if (currentUser) {
        if (!dirty) setFieldValue("requester", currentUser)
        setRequester(currentUser)
      }
    }, [dirty, setFieldValue, currentUser])

    useEffect(() => {
      const selected = values["requester"]
      if (selected) {
        setRequester(selected)
        refetchConfig({
          variables: { employeeId: selected.id },
        })
      }
    }, [values, refetchConfig])

    useEffect(() => {
      if (values.province_departure_place) {
        const [province, district] = values.province_departure_place.split(" » ")

        const hasNestedField = !isNil(district)
        if (hasNestedField) {
          setFieldValue("province_departure_place", province)
          setFieldValue("district_departure_place", district)
        }
      }
    }, [values, setFieldValue])

    useEffect(() => {
      if (values.province_arrival_place) {
        const [province, district] = values.province_arrival_place.split(" » ")

        const hasNestedField = !isNil(district)
        if (hasNestedField) {
          setFieldValue("province_arrival_place", province)
          setFieldValue("district_distances", district)
        }
      }
    }, [values, setFieldValue])

    return {
      titlePage: selectedRequest.subsection,
      claimCard,
      conditions,
      inputs: isPreview ? inputsWithValue : inputs,
      documents: documentWithValue,
      remark,
      section,
      name,
      isPreview,
      handleSubmit,
      handleClickSendRequest,
      handleFilesChange,
      handleClickBack,

      employeeOptions: employeeOptions || [],
      currentUserIsHr,
      requesterFullName: requester ? requester.firstName + " " + requester.lastName : undefined,
      isRepresent,
      amount: values[amountFieldName],
      showUploadFile,
      getOptions,
      hasIsOther,

      step: stepPage,
      approvedCount,
      approvedAmount,
      requestResponse,
      handleClickClaim,

      hrInstead,
    }
  }),
)

export default enhancer(ClaimDetailPage)
