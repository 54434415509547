import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import imageCompression from "browser-image-compression"
import { Box } from "components"
import { Button, IconButton, Modal } from "components/common"
import { useCallback, useMemo, useState } from "react"
import { useDropzone } from "react-dropzone"
import styled from "styled-components"
import { AppColor } from "theme/app-color"
import { convertBase64ToFile, convertFileToBase64 } from "utils/helper"
import { Icon, MuiIconCollections } from "../commons/Icon"
import Typography from "../commons/Typography"

const ItemContainer = styled.div`
  border: 1px solid ${AppColor["Gray/Flat Button Bg"]};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: calc(100% - 32px);
  margin-top: 16px;
`

const ItemTitleContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;
`

const ContainerFlex = styled.div<{ js: string; row?: boolean; al: string }>`
  display: flex;
  justify-content: ${(props) => props.js};
  align-items: ${(props) => props.al};
  flex-direction: ${(props) => (props.row ? "row" : "column")};
`

const IconContainer = styled.div`
  background-color: ${AppColor["Gray/Bg"]};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
  padding: 8px 0px;
  border-radius: 8px;
`

const ItemImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
`

const CloseButton = styled(IconButton)``

const CustomIconList = styled(Icon)`
  font-size: 16px;
  margin-right: 6px;
`

const TopBox = styled(Box)`
  background: ${AppColor["White / White"]};
  width: 100%;
  height: 56px;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export interface DocumentConfig {
  seq: number
  name?: string
  title: string
  iconName: MuiIconCollections
  remark: string
  required?: boolean
  amountLimit?: number
  file?: string
  isPreview?: boolean
  maxSizeInMB?: number
}

export interface DocumentItemProps extends DocumentConfig {
  canDelete: boolean
  onChange: (file: File[], seq: number) => void
}

const DocumentItem = (props: DocumentItemProps) => {
  const {
    amountLimit,
    iconName,
    name,
    remark,
    seq,
    title,
    file: fileBase64,
    onChange,
    canDelete,
    isPreview,
    required,
    maxSizeInMB = 1,
  } = props

  const initialFile: File[] = useMemo(() => (fileBase64 && [convertBase64ToFile(fileBase64, name)]) || [], [
    fileBase64,
    name,
  ])
  const [files, setFiles] = useState<File[]>(initialFile)
  const [isShow, setIsShow] = useState(false)
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: [".png", ".jpeg", ".jpg"],
    multiple: false,
    // maxSize: 1048576,
    onDropAccepted: async (file) => {
      const maxSizeInbytes = maxSizeInMB * 1048576
      if (file[0].size > maxSizeInbytes) {
        const maxSizeMB = maxSizeInMB
        const compressedFile = await imageCompression(file[0], { maxSizeMB, useWebWorker: false, exifOrientation: -2 })
        onChange([compressedFile], seq)
        setFiles([compressedFile])
      } else {
        onChange(file, seq)
        setFiles(file)
      }
    },
  })

  const onClickShowImage = () => {
    setIsShow(true)
  }

  const fileComponent = files.map((file, ind) => (
    <div key={file.name + ind} style={{ objectFit: "contain", width: 200, height: 200 }}>
      <img width={200} height={200} src={URL.createObjectURL(file)} alt={file.name} onClick={onClickShowImage} />
    </div>
  ))

  const onClickRemoveFile = () => {
    Modal.confirm({
      className: "deleteConFirmModal",
      title: "",
      children: (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Box mb="21px">
            <Icon fontSize="large" htmlColor={AppColor["Text/Primary Pink"]} name="DeleteOutline"></Icon>
          </Box>
          <Box mb="8px">
            <Typography variant="h3" color="Text/Black2">
              ลบไฟล์
            </Typography>
          </Box>
          <Box mb="16px">
            <Typography variant="body1" color="Text/Black2">
              คุณยืนยันที่จะลบหรือไม่
            </Typography>
          </Box>
        </Box>
      ),
      okButtonLabel: "ยืนยันลบ",
      okButtonVariant: "outlined",
      okButtonColor: "secondary",
      cancelButtonLabel: "ยกเลิก",
      cancelButtonVariant: "contained",
      cancelButtonColor: "secondary",
      buttonWidth: 147,
      swapButton: true,
      onOk: handleRemoveFile,
    })
  }

  const handleRemoveFile = (confirmProps: any) => {
    confirmProps.close()
    onChange([], seq)
    setFiles([])
    setIsShow(false)
  }

  const handleCloseModal = () => {
    setIsShow(false)
  }

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"))

  return (
    <>
      <ItemContainer>
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
        </div>
        <ItemTitleContainer>
          <ContainerFlex js="" al="start" row>
            <Box mr={2}>
              <Typography variant="h4">{`${seq}. `}</Typography>
            </Box>
            <ContainerFlex js="" al="start">
              <Typography variant="h4" color="Text/Black2">
                {`${title}`}
                {required ? "*" : ""}
              </Typography>
              <Box mt={1}>
                <Typography variant="subtitle1" color="SecondaryText/Gray">
                  {remark ? `(${remark})` : ""}
                </Typography>
              </Box>
            </ContainerFlex>
          </ContainerFlex>
          <Box ml={1}>
            <ContainerFlex js="" al="end" row>
              {files.length < 1 && (
                <Button type="button" onClick={open} style={{ width: "101px" }} color="primary" variant="outlined">
                  <CustomIconList name="Add" />
                  เพิ่มไฟล์
                </Button>
              )}
            </ContainerFlex>
          </Box>
        </ItemTitleContainer>
        {files.length === 0 && (
          <IconContainer>
            {<Icon name="Description" htmlColor={AppColor["Text/Gray Success"]} style={{ fontSize: "60px" }} />}
          </IconContainer>
        )}

        {files.length > 0 && <ItemImageContainer>{fileComponent}</ItemImageContainer>}
      </ItemContainer>
      {files.length > 0 && (
        <>
          <Dialog fullScreen={fullScreen} open={isShow} className="imagePreview">
            <DialogTitle>
              <TopBox>
                <CloseButton onClick={handleCloseModal}>
                  <Icon name="Close" style={{ fontSize: "24px" }} htmlColor={AppColor["Dark Blue/Primary Text"]} />
                </CloseButton>
              </TopBox>
            </DialogTitle>
            <DialogContent
              style={{
                objectFit: "contain",
                padding: "24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box display="flex" flexDirection="column">
                <Box display="flex" alignItems="center" justifyContent="center">
                  <img
                    style={{ maxWidth: "100%", maxHeight: "400px" }}
                    src={URL.createObjectURL(files[0])}
                    alt={files[0].name}
                  />
                </Box>
                {!isPreview && (
                  <Box display="flex" justifyContent="center" alignItems="center" width="100%" mt="40px">
                    <Button onClick={onClickRemoveFile} color="primary" width="147px">
                      ลบไฟล์
                    </Button>
                  </Box>
                )}
              </Box>
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  )
}

const Container = styled.div<{ mb?: string }>`
  margin-top: 40px;
  margin-bottom: ${(props) => (props.mb ? props.mb : "40px")};
`

const Items = styled.div`
  background-color: ${AppColor["White / White"]};
`

export interface UploadDocumentCardProps {
  documents: DocumentConfig[]
  onChange: (documents: DocumentConfig[]) => void
  canDelete?: boolean
  isPreview?: boolean
  maxSizeInMB?: number
  mb?: string
}

export const UploadDocumentCard = (props: UploadDocumentCardProps) => {
  const { documents, onChange, canDelete = true, isPreview = false, maxSizeInMB = 1, mb } = props

  const handleChangeFile = useCallback(
    async (value: File[], seq: number) => {
      const existed = documents.find((doc) => doc.seq === seq)
      const newItems = documents.filter((doc) => doc.seq !== seq)

      if (existed) {
        const newItem = { ...existed, file: value[0] ? await convertFileToBase64(value[0]) : undefined }
        onChange([...newItems, newItem])
      }
    },
    [documents, onChange],
  )

  const isShowItem = (file?: string) => {
    if (isPreview) {
      if (file) return true
      return false
    }
    return true
  }

  return (
    <Container mb={mb}>
      {documents.length > 0 && (
        <ContainerFlex js="" al="center" row>
          <Icon
            style={{ fontSize: "22px", transform: "rotate(45deg)", marginRight: "10px" }}
            name="AttachFile"
            htmlColor={AppColor["Text/Black2"]}
          />
          <Typography variant="h3" color="Text/Black2">
            {isPreview ? "เอกสารแนบ" : "แนบเอกสาร"}
          </Typography>
        </ContainerFlex>
      )}
      <Items>
        {documents.map((doc, ind) => {
          if (isShowItem(doc.file))
            return (
              <DocumentItem
                key={ind}
                seq={doc.seq}
                title={doc.title}
                iconName={doc.iconName}
                remark={doc.remark}
                canDelete={canDelete}
                onChange={handleChangeFile}
                file={doc.file}
                required={doc.required}
                isPreview={isPreview}
                maxSizeInMB={maxSizeInMB}
              />
            )
        })}
      </Items>
    </Container>
  )
}
