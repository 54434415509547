import { DefaultPreviewComponent } from "./Default"

type PreviewType = "default"

interface PreviewFieldProps {
  previewType?: PreviewType
  previewProps: BasePreviewProps
}

export interface BasePreviewProps {
  label: string
  value?: string
  component: string
  unit?: string
}

const MapTypeToPreview = (type: PreviewType, props: BasePreviewProps) => {
  switch (type) {
    default:
      return <DefaultPreviewComponent {...props} />
  }
}

export const PreviewField = (props: PreviewFieldProps) => {
  const { previewType = "default", previewProps } = props
  return <>{MapTypeToPreview(previewType, previewProps)}</>
}
