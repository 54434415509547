import AppTxt from "components/commons/Typography"
import styled from "styled-components"

interface Condition {
  description: string
  pointRequired: boolean
  withDrawAmountLimit: number
  withdrawTimesPerYear: number
}

interface ClaimConditionProps {
  conditions: Condition[]
}

const Container = styled.div`
  margin-top: 24px;
`

const List = styled.ul`
  margin-top: 8px;
  margin-left: -16px;
`

const ClaimConditions = (props: ClaimConditionProps) => {
  const { conditions } = props
  return (
    <Container>
      {conditions.length > 0 && (
        <AppTxt variant="h3" color="Text/Black2">
          เงื่อนไขการเบิก
        </AppTxt>
      )}
      <List>
        {conditions.map((condition, ind) => (
          <li key={ind}>
            <AppTxt variant="h4" color="SecondaryText/Gray">
              {condition.description}
            </AppTxt>
          </li>
        ))}
      </List>
    </Container>
  )
}

export default ClaimConditions
