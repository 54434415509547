import { Box, Grid } from "components"
import { compose, withFormik, withHooks, withStores } from "enhancers"
import { PageContent, PageFooter } from "layouts"
import styled from "styled-components"
import { ClaimCard } from "components/advance/ClaimCard"
import { Icon } from "components/commons/Icon"
import Typography from "components/commons/Typography"
import paths from "routes/paths"
import { AppColor } from "theme/app-color"
import { API } from "../e-claim-landing"
import { employeeIsHr } from "utils/helper"

const ContainerFlex = styled.div<{ js: string; row?: boolean; w100?: boolean; mt?: string; al?: string }>`
  display: flex;
  justify-content: ${(props) => props.js};
  flex-direction: ${(props) => (props.row ? "row" : "column")};
  width: ${(props) => (props.w100 ? "100%" : "none")};
  margin-top: ${(props) => (props.mt ? props.mt : "0px")};
  align-items: ${(props) => props.al};
`

const LandingPageComponent = (props: any) => (
  <>
    <PageContent
      title="สวัสดิการทั้งหมด"
      titleCentered
      titleDark
      onBack={props.handleClose}
      bgColor={AppColor["Gray/Bg"]}
      // childrenPadding="4px"
    >
      <Grid container>
        {props.layouts.map((layout: any, index: number) => (
          <>
            <ContainerFlex js="start" w100>
              <ContainerFlex js="flex-start" al="center" row w100 mt={index === 0 ? "8px" : "32px"}>
                <Box mr="12px" display="flex" alignItems="center" justifyContent="center">
                  <Icon name={layout.icon} fontSize="small" htmlColor={layout.iconColor} />
                </Box>
                <Typography variant="h3" color={layout.titleColor}>
                  {layout.title}
                </Typography>
              </ContainerFlex>
              <Box mt="4px" mb="8px">
                <Typography variant="subtitle2" color="SecondaryText/Gray">
                  {layout.description}
                </Typography>
              </Box>
            </ContainerFlex>
            {layout.sectionList.map((section: any) => (
              <>
                <ContainerFlex js="start" row w100 mt="8px">
                  <Typography variant="h6" color="Text/Black2">
                    {section.requestTypeTitle}
                  </Typography>
                </ContainerFlex>
                <Grid container mt="8px" spacing={3}>
                  {props.claimRequests(section.requestTypeList).map((data: any, index: number) => (
                    <ClaimCard
                      key={data.name}
                      onClick={() => props.handleClickRequest(data)}
                      type={data.section}
                      name={data.name}
                      subTitle={data.claimCard.subTitle}
                      approvedRequest={data.approvedCount}
                      totalAmount={data.approvedAmount}
                      title={data.claimCard.title}
                      icon={data.claimCard.icon}
                      iconColor={data.claimCard.iconColor}
                      titleColor={data.claimCard.titleColor}
                      chipColor={data.claimCard.chipColor}
                    />
                  ))}
                </Grid>
              </>
            ))}
          </>
        ))}
      </Grid>
      <Box style={{ height: "82px" }}></Box>
    </PageContent>
    <PageFooter page="claim-list" isHr={props.userIsHr} />
  </>
)

const enhancer = compose(
  withFormik({}),
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
  })),
  withHooks((props: any, hooks: any) => {
    const { currentUserInfo } = props
    const { useQuery, useCallback, useMemo } = hooks
    const { data } = useQuery(API.GET_CLAIM_REQUEST_CONFIGS, {
      variables: {
        employeeId: currentUserInfo.employee.id,
      },
      fetchPolicy: "network-only",
    })

    const userIsHr = useMemo(() => employeeIsHr(currentUserInfo.employee.role), [currentUserInfo])
    const handleClickRequest = (value: any) => {
      localStorage.setItem("selected_request", JSON.stringify(value))
    }

    const handleClose = useCallback(() => {
      paths.landingPath().push()
    }, [])

    const claimRequests = useCallback(
      (requests: string[]) => {
        if (data?.claimRequestConfigs.requests)
          return data?.claimRequestConfigs.requests.filter((request: any) => requests.includes(request.name))
        return []
      },
      [data?.claimRequestConfigs],
    )

    return {
      layouts: data?.claimRequestConfigs.layouts || [],
      claim_requests: data?.claimRequestConfigs.requests || [],
      handleClickRequest,
      handleClose,
      claimRequests,
      userIsHr,
    }
  }),
)

export default enhancer(LandingPageComponent)
