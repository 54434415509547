import { Dialog as MuiDialog, DialogActions, DialogContent } from "@material-ui/core"
import { DatePicker } from "@material-ui/pickers"
import { ReactComponent as DatePickerIcon } from "assets/icon/datepicker_icon.svg"
import { Box, Button } from "components"
import Typography from "components/commons/Typography"
import dayjs from "dayjs"
import { useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import { toCurrency } from "utils/helper"

const Dialog = styled(MuiDialog)`
  .MuiDialog-paperScrollPaper {
    padding: 0;
    margin: 1vw;
    min-width: 343px;
    overflow: hidden;
  }
`

const ModalContainer = styled(DialogContent)`
  /*max-width: 500px;
  min-width: 340px;*/
  height: 100%;
  /*min-height: 200px;*/
  padding: 16px;
`

const ContentContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const TitleContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0px auto;
  margin-bottom: 16px;
`

const ActionContainer = styled(Box)`
  display: flex;
  width: 100%;
  padding: 0px 16px 16px 16px;
`

const ActionButton = styled(Button)`
  width: 100%;
`

interface ApproveModalProps {
  isOpen: boolean
  title: string
  onClose: () => void
  onConfirm: (date: string) => void
  amount: string
  isInsurance: string
  defaultTransferDate: dayjs.Dayjs
}

export const ApproveModal = (props: ApproveModalProps) => {
  const { isOpen, onClose, title, onConfirm, amount, isInsurance, defaultTransferDate } = props

  const [value, setValue] = useState(defaultTransferDate.toDate())
  const handleChangeValue = useCallback((value: any) => {
    setValue(dayjs(value).toDate())
  }, [])

  const handleClose = useCallback(() => {
    setTimeout(() => setValue(defaultTransferDate.toDate()), 500)
    onClose()
  }, [onClose, defaultTransferDate])

  const handleConfirm = useCallback(() => {
    const dateWithFormat = dayjs(value).format("YYYY-MM-DD")
    onConfirm(dateWithFormat)
    handleClose()
  }, [onConfirm, handleClose, value])

  return (
    <Dialog open={isOpen}>
      <ModalContainer>
        <ContentContainer>
          <TitleContainer>
            <Typography variant="h3" color="Text/Black2">
              ยอมรับการเบิกสวัสดิการ ?
            </Typography>
          </TitleContainer>
          <Box mb="4px">
            <Typography variant="body1" color="Text/Gray Preview">
              อนุมัติการเบิก
            </Typography>
          </Box>
          <Box mb="16px">
            <Typography variant="h6" color="Text/Blue">
              {title + " " + amount + " "}
              บาท
            </Typography>
          </Box>
        </ContentContainer>
        <Typography variant="body1" color="Text/Black2">
          {isInsurance ? "วันที่ส่งเอกสารการเคลมให้กับบริษัทประกัน*" : "วันที่โอนเงินเข้าบัญชีเงินเดือนพนักงาน*"}
        </Typography>
        <Box position="relative">
          <DatePicker
            style={{ marginTop: 8 }}
            label=""
            value={value}
            format="dd/MM/yyyy"
            onChange={handleChangeValue}
          />
          <Box position="absolute" top={18} right={12}>
            <DatePickerIcon />
          </Box>
        </Box>
      </ModalContainer>
      <DialogActions>
        <ActionContainer>
          <ActionButton variant="outlined" color="primary" onClick={handleClose} mr="16px">
            ปิดหน้าต่างนี้
          </ActionButton>
          <ActionButton variant="contained" color="primary" onClick={handleConfirm}>
            ยืนยัน
          </ActionButton>
        </ActionContainer>
      </DialogActions>
    </Dialog>
  )
}
