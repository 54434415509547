import { theme } from "../../constants"
import { PropsWithChildren } from "react"
import { LinkProps, Link as ReactRouterLink } from "react-router-dom"
import styled from "styled-components"

const CustomLink = styled(ReactRouterLink)`
  color: ${theme.color.dark.primary};
  text-decoration: none;
`

const Link = (props: PropsWithChildren<LinkProps>) => {
  const { children } = props
  return <CustomLink {...props}>{children}</CustomLink>
}

export default Link
