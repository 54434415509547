import React from "react"
import { compose, withHooks, withStores, withFormik } from "enhancers"
import { get, isFunction, isString } from "lodash"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Button from "./Button"
import appStore from "stores/appStore"
import { Box } from "."

const ModalComponent = ({
  isShow,
  title,
  children,
  cancelButtonLabel,
  okButtonLabel,
  disabled,
  fullWidth,
  maxWidth,
  onOk,
  onCancel,
  onClose,
  hideFooter,
  okButtonVariant = "text",
  okButtonColor = "text",
  cancelButtonVariant = "text",
  cancelButtonColor = "text",
  buttonWidth = 100,
  swapButton = false,
  ...rest
}) => (
  <Dialog open={isShow} onClose={disabled ? undefined : onClose} fullWidth={fullWidth} maxWidth={maxWidth} {...rest}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent style={{ padding: "0px" }}>
      {isString(children) ? (
        <DialogContentText>{children}</DialogContentText>
      ) : isFunction(children) ? (
        children()
      ) : (
        children
      )}
    </DialogContent>
    {!hideFooter ? (
      <>
        {swapButton ? (
          <DialogActions>
            <Box display="flex" flex={1} justifyContent="center" alignItem="center" width="100%">
              {onOk && (
                <Button
                  variant={okButtonVariant}
                  onClick={() => onOk(rest)}
                  color={okButtonColor ?? "primary"}
                  size="small"
                  disabled={disabled}
                  style={{ marginRight: 16, color: cancelButtonColor ? "" : "#767676", width: buttonWidth, flex: 1 }}
                >
                  {okButtonLabel}
                </Button>
              )}
              {onCancel && (
                <Button
                  variant={cancelButtonVariant}
                  onClick={onCancel}
                  color={cancelButtonColor ?? "light"}
                  size="small"
                  disabled={disabled}
                  style={{ width: buttonWidth, flex: 1 }}
                >
                  {cancelButtonLabel}
                </Button>
              )}
            </Box>
          </DialogActions>
        ) : (
          <DialogActions>
            <Box display="flex" flex={1} justifyContent="center" alignItem="center" width="100%">
              {onCancel && (
                <Button
                  variant={cancelButtonVariant}
                  onClick={onCancel}
                  color={cancelButtonColor ?? "light"}
                  size="small"
                  disabled={disabled}
                  style={{ marginRight: 16, color: cancelButtonColor ? "" : "#767676", width: buttonWidth, flex: 1 }}
                >
                  {cancelButtonLabel}
                </Button>
              )}
              {onOk && (
                <Button
                  variant={okButtonVariant}
                  onClick={() => onOk(rest)}
                  color={okButtonColor ?? "primary"}
                  size="small"
                  disabled={disabled}
                  style={{ width: buttonWidth, flex: 1 }}
                >
                  {okButtonLabel}
                </Button>
              )}
            </Box>
          </DialogActions>
        )}
      </>
    ) : null}
  </Dialog>
)

const enhancer = compose(
  withStores((stores, props) => (props.storeName ? get(stores, props.storeName) : {})),
  withFormik({}),
  withHooks((props, hooks) => {
    const { storeName, dispatch, ...rest } = props
    const { useEffect } = hooks
    useEffect(() => {
      if (!rest.isShow) {
        rest.resetForm()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rest.isShow, rest.resetForm])

    return rest
  }),
)

const Modal = enhancer(ModalComponent)

Modal.open = appStore.openMainModal
Modal.close = appStore.closeMainModal
Modal.alert = appStore.openAlertModal
Modal.confirm = appStore.openConfirmModal

export default Modal
