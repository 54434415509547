import { TypographyOptions } from "@material-ui/core/styles/createTypography"

const typography: TypographyOptions = {
  fontFamily: [
    "Kanit",
    "Inter",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  fontSize: 16,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  allVariants: {
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  h1: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: "31.2px",
  },
  h2: {
    fontSize: 22,
    fontWeight: 700,
    lineHeight: "28px",
  },
  h3: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "24px",
  },
  h4: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "24px",
  },
  h5: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "24px",
  },
  h6: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: "20px",
  },
  body1: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "20px",
  },
  body2: {
    fontSize: 12,
    fontWeight: 700,
    lineHeight: "16px",
  },
  subtitle1: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "16px",
  },
  subtitle2: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "16px",
  },
  caption: {
    fontSize: 10,
    fontWeight: 500,
    lineHeight: "16px",
  },
  button: {
    textTransform: "none",
  },
}

export default typography
