import { SvgIconProps } from "@material-ui/core"
import * as MuiIcon from "@material-ui/icons"
import React from "react"

export type MuiIconCollections = keyof typeof MuiIcon

interface CustomIconProps extends SvgIconProps {
  name?: MuiIconCollections
}

export const Icon = (props: CustomIconProps) => {
  const { name, ...rest } = props

  if (MuiIcon[name as MuiIconCollections])
    return React.createElement(MuiIcon[name as MuiIconCollections], {
      ...rest,
    })
  else return <>Icon Not Found</>
}
