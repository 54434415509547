import { ApolloError, gql, useQuery } from "@apollo/client"
import { compose, withHooks } from "enhancers"
import { useEffect } from "react"
import paths from "routes/paths"

import appStore from "stores/appStore"

import { Redirect, Route, Switch } from "components"
import Page404 from "pages/auth/Page404"
import ClaimDetailPage from "pages/main/claim-detail/index"
import ListPageComponent from "pages/main/e-claim-list/index"
import eClaimLanding from "pages/main/e-claim-landing"
import HistoryComponent from "pages/main/e-claim-history"
import HistoryDetailComponent from "pages/main/e-claim-history/detail"
import ClaimRequestPage from "pages/main/claim-requests"
import ApprovalPage from "pages/main/claim-requests/detail"
import { removeToken, setToken } from "api"
import userStore from "stores/userStore"
import Page500 from "pages/auth/Page500"
import Page498 from "pages/auth/Page498"
import DashboardComponent from "pages/main/dashboard"
import dashboardDetail from "pages/main/dashboard/dashboardDetail"

const MainPages = () => (
  <Switch>
    <Route path={paths.landingPath()} exact component={eClaimLanding} />
    <Route path={paths.claimDetailPath()} exact component={ClaimDetailPage} />
    <Route path={paths.listPath()} exact component={ListPageComponent} />
    <Route path={paths.dashboardPath()} exact component={DashboardComponent} />
    <Route path={paths.dashboardDetailPath(":id")} exact component={dashboardDetail} />
    <Route path={paths.historyPath()} exact component={HistoryComponent} />
    <Route path={paths.historyDetailPath(":id")} exact component={HistoryDetailComponent} />
    <Route path={paths.claimRequestsPath()} exact component={ClaimRequestPage} />
    <Route path={paths.approvalRequestPath(":id")} exact component={ApprovalPage} />
    {/* <Redirect to={paths.landingPath()} /> */}
  </Switch>
)

const GuestPages = () => (
  <Switch>
    <Route path={paths.page404Path()} exact component={Page404} />
    <Route path={paths.page500Path()} exact component={Page500} />
    <Route path={paths.page498Path()} exact component={Page498} />

    {/* <Redirect to={paths.page404Path()} /> */}
  </Switch>
)

interface RoutesProps {
  initialized: boolean
  isAuthorized: boolean
}

const Routes = (props: RoutesProps) => {
  const { initialized, isAuthorized } = props
  if (!initialized) return <GuestPages />
  else if (!isAuthorized) return <GuestPages />
  else return <MainPages />
}

export const API = {
  GET_CURRENT_USER: gql`
    query GET_CURRENT_USER {
      getCurrentUser {
        id
        employee {
          id
          employeeCode
          firstName
          lastName
          role
          grade
          employmentStartDate
          employmentType
          probationPeriod
          probationStatus
          functionalDesignation
        }
      }
    }
  `,
}

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const { useState, useQuery, useUrlParam } = hooks
    const { token } = useUrlParam()

    const [isReady, setIsReady] = useState(false)
    const currentUser = useQuery(API.GET_CURRENT_USER, {
      onCompleted: (data: any) => {
        // console.log("Success : ", data)
        userStore.setCurrentUser(data.getCurrentUser)
        setIsReady(true)
        if (token) window.location.href = paths.landingPath()
      },
      onError: (error: ApolloError) => {
        console.log("Error : ", error.networkError)
        userStore.setCurrentUser(null)
        setIsReady(true)
      },
    })

    const initialized = isReady
    const isAuthorized = !currentUser.error

    useEffect(() => {
      const $splashScreen = document.getElementById("splash-screen")
      if ($splashScreen) {
        // const display = initialized ? "none" : ""
        $splashScreen.style.display = "none"
      }
    }, [])

    useEffect(() => {
      if (token) {
        setToken(token)
        console.log("Token:", token)
      }
    }, [token])

    return {
      initialized,
      isAuthorized,
    }
  }),
)

export default enhancer(Routes)
