import { useCallback } from "react"
import { Icon } from "components/commons/Icon"
import DatabaseGreenIcon from "assets/icon/database_green.svg"
import DentistryPinkIcon from "assets/icon/dentistry_pink.svg"
import DentistryBlueIcon from "assets/icon/dentistry_blue.svg"
import PaymentsIcon from "assets/icon/payments.svg"
import MedicalServicesIcon from "assets/icon/medical_services.svg"
import ChildCareIcon from "assets/icon/child_care.svg"
import PassingIcon from "assets/icon/passing.svg"
import VaccinesIcon from "assets/icon/vaccines.svg"

interface IconListProps {
  name?: string
  fullSize?: boolean
  iconName?: keyof typeof Icon
  iconColor?: string
}

export const IconList = (props: IconListProps) => {
  const { name, fullSize, iconName, iconColor } = props

  const iconList = useCallback(
    (name) => {
      switch (name) {
        case "amataAllowance":
          return (
            <img
              src={DatabaseGreenIcon}
              style={{ width: fullSize ? "24px" : "auto", height: fullSize ? "24px" : "auto" }}
              alt=""
            />
          )
        case "employeeDental":
          return (
            <img
              src={DentistryPinkIcon}
              style={{ width: fullSize ? "24px" : "auto", height: fullSize ? "24px" : "auto" }}
              alt=""
            />
          )
        case "familyDental":
          return (
            <img
              src={DentistryPinkIcon}
              style={{ width: fullSize ? "24px" : "auto", height: fullSize ? "24px" : "auto" }}
              alt=""
            />
          )
        case "firstTripAllowance":
          return (
            <img
              src={PaymentsIcon}
              style={{ width: fullSize ? "29px" : "auto", height: fullSize ? "21px" : "auto" }}
              alt=""
            />
          )
        case "dentalCare":
          return (
            <img
              src={DentistryBlueIcon}
              style={{ width: fullSize ? "24px" : "auto", height: fullSize ? "24px" : "auto" }}
              alt=""
            />
          )
        case "employeeOpd":
          return (
            <img
              src={MedicalServicesIcon}
              style={{ width: fullSize ? "27px" : "auto", height: fullSize ? "27px" : "auto" }}
              alt=""
            />
          )
        case "familyOpd":
          return (
            <img
              src={MedicalServicesIcon}
              style={{ width: fullSize ? "27px" : "auto", height: fullSize ? "27px" : "auto" }}
              alt=""
            />
          )
        case "childbirthCongratulation":
          return (
            <img
              src={ChildCareIcon}
              style={{ width: fullSize ? "24px" : "auto", height: fullSize ? "24px" : "auto" }}
              alt=""
            />
          )
        case "employeeFamilyPassingAway":
          return (
            <img
              src={PassingIcon}
              style={{ width: fullSize ? "27px" : "auto", height: fullSize ? "27px" : "auto" }}
              alt=""
            />
          )
        case "employeePassingAway":
          return (
            <img
              src={PassingIcon}
              style={{ width: fullSize ? "27px" : "auto", height: fullSize ? "27px" : "auto" }}
              alt=""
            />
          )
        case "medicalFee":
          return (
            <img
              src={VaccinesIcon}
              style={{ width: fullSize ? "25px" : "auto", height: fullSize ? "27px" : "auto" }}
              alt=""
            />
          )
        default:
          return <Icon style={{ fontSize: fullSize ? "32px" : "40px" }} name={iconName} htmlColor={iconColor} />
      }
    },
    [iconName, fullSize, iconColor],
  )

  return <>{iconList(name)}</>
}
