import { compose, defaultProps, withHooks } from "enhancers"
import { Box } from "components"
import { DatePicker as FormikMuiDatePicker } from "formik-material-ui-pickers"
import { format } from "date-fns"
import { get } from "lodash"

import { ReactComponent as DatePickerIcon } from "assets/icon/datepicker_icon.svg"

const DatePicker = (props) => (
  <Box width={props.width} position="relative">
    <FormikMuiDatePicker {...props} />
    <Box position="absolute" top={12} right={12}>
      <DatePickerIcon />
    </Box>
  </Box>
)

const enhancer = compose(
  defaultProps({ format: "dd/MM/yyyy", placeholder: "วันที่" }),
  withHooks((props, hooks) => {
    const { useMemo } = hooks

    const error = useMemo(() => {
      const touched = get(props.form.touched, props.field.name) || props.form.submitCount > 0
      const error = get(props.form.errors, props.field.name)
      return touched && !!error
    }, [props.form, props.field])

    return {
      ...props,
      error,
      labelFunc: (date, invalidLabel) => (props.field.value ? format(date, props.format) : ""),
    }
  }),
)

export default enhancer(DatePicker)
