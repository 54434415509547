import { Box, Modal, Typography } from "components"
import { gql } from "@apollo/client"
import { ClaimRequestHistoryItem } from "components/advance/ClaimRequestHistoryItem"
import { StatusTab } from "components/advance/StatusTab"
import { EnumClaimRequestStatus } from "constants/enums/claim-request-status"
import { compose, withFormik, withHooks, withStores } from "enhancers"
import { PageContent } from "layouts/PageContent"
import paths from "routes/paths"
import styled from "styled-components"
import FilterClaimRequests from "./FilterModal"
import { AppColor } from "theme/app-color"
import { every, isEmpty, isNull } from "lodash"

const HistoryContainer = styled.div`
  margin-top: 16px;
`

const ClaimRequestsPageComponent = (props: any) => (
  <PageContent
    title="รายการขอเบิก"
    titleCentered
    titleDark
    onBack={props.handleClickBack}
    titleBg={AppColor["White / White"]}
    showFilter
    hasFilter={props.hasFilter}
    openFilterModal={props.openFilterModal}
  >
    <StatusTab
      selectedStatus={props.filteredStatus}
      onClick={(status: EnumClaimRequestStatus) => props.handleChangeStatus(status)}
      hasCancel={false}
    />
    <HistoryContainer>
      {props.showNotFoundSearch ? (
        <Box>
          <Box display="flex" alignItems="center">
            <Typography variant="h3" color="Gray/Secondary Text" mr={2}>
              รายการค้นหา
            </Typography>
            <Typography variant="subtitle2" color="Gray/Primary Text" mt={0.5}>
              (0 รายการ)
            </Typography>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            position="fixed"
            top="40%"
            left="10%"
            right="10%"
          >
            <Typography variant="h3" color="Gray/Secondary Text" mb={4}>
              ไม่พบรายการที่ต้องการ
            </Typography>
            <Typography variant="h4" color="Gray/Placeholder">
              กรุณาตรวจสอบข้อมูลตัวกรองที่ใช้ค้นหาอีกครั้ง
            </Typography>
          </Box>
        </Box>
      ) : (
        <>
          {props.claimRequestList.map((history: any) => (
            <ClaimRequestHistoryItem
              key={history.id}
              id={history.id}
              info={history.info}
              workflowSeq={history.workflowSeq}
              createdAt={history.createdAt}
              referenceId={history.referenceId}
              amount={props.requestAmount(history)}
              onClick={(id: string) => props.handleClickHistoryItem(id)}
              owner={history.employee}
              createdBy={history.createdBy}
              userRole={props.currentUser.role}
              status={history.status}
              filteredStatus={props.filteredStatus}
            />
          ))}
        </>
      )}
    </HistoryContainer>
  </PageContent>
)

const API = {
  GET_CLAIM_REQUEST_LIST: gql`
    query GET_CLAIM_REQUEST_LIST($status: String!, $filters: JSON) {
      claimRequestList(input: { status: $status, filters: $filters }) {
        workflowSeq
        id
        type
        status
        config
        info
        employee
        createdAt
        createdBy
        referenceId
      }
    }
  `,
}

const enhancer = compose(
  withFormik({}),
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
  })),
  withHooks((props: any, hooks: any) => {
    const { currentUserInfo, values, setValues } = props
    const { useQuery, useEffect, useCallback, useMemo, useState } = hooks
    const currentUser = useMemo(() => {
      return currentUserInfo.employee
    }, [currentUserInfo])
    const [filteredStatus, setFilteredStatus] = useState(EnumClaimRequestStatus.WAITING)
    const { data, refetch } = useQuery(API.GET_CLAIM_REQUEST_LIST, {
      variables: { status: filteredStatus },
    })

    const [canSubmit, setCanSubmit] = useState(false)
    const [hasFilter, setHasFilter] = useState(false)

    const handleChangeStatus = useCallback((status: EnumClaimRequestStatus) => {
      setFilteredStatus(status)
    }, [])

    const requestAmount = useCallback((history: any) => {
      let amount = 0
      history.info.inputs.forEach((input: any) => {
        if (input.name === history.info.amountFieldName) {
          amount = input.value
        }
      })
      return amount
    }, [])

    const handleClickHistoryItem = useCallback((id: string) => {
      paths.approvalRequestPath(id).push()
    }, [])

    const handleClickBack = useCallback(() => {
      paths.landingPath().push()
    }, [])

    useEffect(() => {
      if (currentUser && filteredStatus) {
        refetch({ filters: values })
      }
    }, [refetch, currentUser, filteredStatus])

    const openFilterModal = useCallback(() => {
      Modal.open({
        className: "FilterModal",
        children: <FilterClaimRequests setOutsideFormValues={setValues} filterValues={values} />,
        cancelButtonLabel: "ปิดหน้าต่างนี้",
        cancelButtonVariant: "outlined",
        cancelButtonColor: "Primary",
        okButtonLabel: "ยืนยัน",
        okButtonVariant: "contained",
        okButtonColor: "Primary",
        onOk: async ({ close }: any) => {
          setCanSubmit(true)
        },
      })
    }, [values])

    useEffect(() => {
      if (canSubmit) {
        const fetchData = async () => {
          await refetch({ filters: values })

          console.log(values, !isEmpty(values))
          if (!isEmpty(values)) setHasFilter(true)
          if (every(values, (value) => !value)) setHasFilter(false)
          setCanSubmit(false)
          Modal.close()
        }
        fetchData()
      }
    }, [values, refetch, canSubmit])

    const showNotFoundSearch = data?.claimRequestList.length === 0 && hasFilter

    return {
      handleChangeStatus,
      claimRequestList: data?.claimRequestList || [],
      requestAmount,
      handleClickHistoryItem,
      filteredStatus,
      handleClickBack,
      currentUser,
      openFilterModal,
      hasFilter,
      showNotFoundSearch,
    }
  }),
)

const ClaimRequestPage = enhancer(ClaimRequestsPageComponent)

export default ClaimRequestPage
