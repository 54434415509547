import AppTxt from "components/commons/Typography"
import styled from "styled-components"

interface Remark {
  description: string
}

interface ClaimRemarkProps {
  remarks: Remark[]
  step: number
}

const Container = styled.div<{ step?: number }>`
  margin-bottom: ${(props) => (props.step === 1 ? "40px" : "24px")};
`

const List = styled.ul`
  margin-top: 4px;
  margin-left: -16px;
`

const ClaimRemarks = (props: ClaimRemarkProps) => {
  const { remarks, step } = props
  return (
    <>
      <Container step={step}>
        {remarks.length > 0 && (
          <AppTxt variant="h5" color="SecondaryText/Gray">
            หมายเหตุ :
          </AppTxt>
        )}
        <List>
          {remarks.map((remark, ind) => (
            <li key={ind}>
              <AppTxt variant="h5" color="SecondaryText/Gray">
                {remark.description}
              </AppTxt>
            </li>
          ))}
        </List>
      </Container>
    </>
  )
}

export default ClaimRemarks
