import "./wdyr"

import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"

import React from "react"
import ReactDOM from "react-dom"
import "./mocks"

import { Provider } from "react-redux"

import { ApiProvider } from "api"

import { HelmetProvider, Helmet } from "react-helmet-async"
import DateFnsUtils from "@date-io/date-fns"

import { ThemeProvider } from "styled-components/macro"
import { create } from "jss"

import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import { StylesProvider, ThemeProvider as MuiThemeProvider, jssPreset } from "@material-ui/core/styles"

import createTheme from "theme"

import { THEMES } from "./constants"

import { BrowserRouter, Modal, Notification, ExcelGenerator, ImageLightbox } from "components"
import store from "stores"

import Routes from "routes/Routes"

import { SnackbarProvider, MaterialDesignContent } from "notistack"

import { Icon } from "components/commons/Icon"

import styled from "styled-components"

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-success": {
    backgroundColor: "#009CA6",
  },
}))

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point")!,
})

const theme = createTheme(THEMES.DEFAULT)

ReactDOM.render(
  <Provider store={store}>
    <ApiProvider>
      <HelmetProvider>
        <Helmet titleTemplate="E-Claim" defaultTitle="E-Claim" />
        <StylesProvider jss={jss}>
          <SnackbarProvider
            maxSnack={3}
            iconVariant={{
              success: <Icon name="CheckBoxOutlined" style={{ marginRight: "10px" }} />,
            }}
            Components={{
              success: StyledMaterialDesignContent,
            }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <MuiThemeProvider theme={theme}>
                <ThemeProvider theme={theme}>
                  <BrowserRouter>
                    <Routes />
                    <Modal storeName="appStore.mainModal" />
                    <Modal storeName="appStore.alertModal" />
                    <Modal storeName="appStore.confirmModal" />
                    <ImageLightbox storeName="appStore.imageLightbox" />
                    <Notification />
                    <ExcelGenerator storeName="appStore.excelGenerator" />
                  </BrowserRouter>
                </ThemeProvider>
              </MuiThemeProvider>
            </MuiPickersUtilsProvider>
          </SnackbarProvider>
        </StylesProvider>
      </HelmetProvider>
    </ApiProvider>
  </Provider>,
  document.getElementById("root"),
)
