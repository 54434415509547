import { Box, Grid, Link } from "components"
import { Icon } from "components/commons/Icon"
import Typography from "components/commons/Typography"
import styled from "styled-components"
import { AppColor } from "theme/app-color"
import { toCurrency } from "utils/helper"
import { IconList } from "./IconList"

const Categories = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  border-radius: 4px;
  height: fit-content;
  min-width: fit-content;
  padding: 2px;
  margin-left: 8px;
`

const CategoriesComponent = (props: { type: string; chipColor: string; titleColor: string }) => {
  const { type, chipColor, titleColor } = props

  return (
    <Categories color={chipColor}>
      <Typography variant="caption" color={titleColor}>
        {type}
      </Typography>
    </Categories>
  )
}

const FooterContainer = styled.div`
  bottom: 4px;
  padding: 8px 0px 0px 0px;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  border-top: 1px solid ${AppColor["Gray/Flat Button Bg"]};
`

const ContainerFlex = styled.div<{ js: string; row?: boolean; al: string }>`
  display: flex;
  justify-content: ${(props) => props.js};
  align-items: ${(props) => props.al};
  flex-direction: ${(props) => (props.row ? "row" : "column")};
`

interface FooterProps {
  totalAmount: number
  approvedRequest: number
}

const Footer = (props: FooterProps) => {
  const { totalAmount, approvedRequest } = props
  return (
    <FooterContainer>
      <Grid item xs={12} sm={12}>
        <ContainerFlex js="space-between" row al="">
          <ContainerFlex js="" al="flex-start">
            <Box mb="8px">
              <Typography variant="subtitle1" color="Gray/Flat Button Bg">
                จำนวนครั้งที่เบิก
              </Typography>
            </Box>
            <Typography variant="body2" color="Text/Black2">
              {toCurrency(approvedRequest, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}
            </Typography>
          </ContainerFlex>
          <ContainerFlex js="" al="flex-end">
            <Box mb="8px">
              <Typography variant="subtitle1" color="Text/Gray Success">
                จำนวนเงินที่เบิก
              </Typography>
            </Box>
            <Typography variant="body2" color="Text/Black2">
              {toCurrency(totalAmount, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}
            </Typography>
          </ContainerFlex>
        </ContainerFlex>
      </Grid>
    </FooterContainer>
  )
}

const iconContainerHeight: any = {
  taxiForDriver: "96px",
  taxiForStaff: "96px",
  amataAllowance: "96px",
  otFixrate: "96px",
  domestic: "116px",
  internationalTripAllowance: "116px",
  firstTripAllowance: "136px",
  weddingCongratulation: "96px",
  childbirthCongratulation: "96px",
  employeeFamilyPassingAway: "156px",
  employeePassingAway: "156px",
  employeeOpd: "96px",
  employeeIpd: "96px",
  employeeDental: "96px",
  employeeAccident: "96px",
  familyOpd: "96px",
  familyIpd: "96px",
  familyDental: "96px",
  familyAccident: "96px",
  medicalFee: "96px",
  dentalCare: "96px",
}

const Container = styled.div<{ fullsize: boolean; name?: any }>`
  background-color: ${AppColor["White / White"]};
  border: 0.5px solid ${AppColor["Gray/Flat Button Bg"]};
  box-shadow: 0px 4px 4px rgba(218, 218, 218, 0.25);
  border-radius: 8px;
  height: ${(props) => {
    return iconContainerHeight[props.name]
  }};
  /* width: ${(props) => (props.fullsize ? "inherit" : "165px")};*/
  padding: ${(props) => (props.fullsize ? "16px" : "0px")};
  margin: ${(props) => (props.fullsize ? "0px" : "0px")};
  text-overflow: ellipsis;
  overflow-x: hidden;
  position: relative;
  text-align: ${(props) => (props.fullsize ? "" : "center")};
  margin-bottom: ${(props) => (props.fullsize ? "8px" : "0px")};
  box-sizing: border-box;
`

const IconContainer = styled.div<{ fullsize: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${(props) => (props.fullsize ? "0px" : "4px")};
  margin-right: ${(props) => (props.fullsize ? "8px" : "0px")};
  margin-top: ${(props) => (props.fullsize ? "0px" : "16px")};
`

const IconBox = styled(Box)<{ fullsize: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.fullsize ? "32px" : "40px")};
  height: ${(props) => (props.fullsize ? "32px" : "40px")};
`

export interface ClaimCardProps {
  type?: string
  name?: string
  title: string
  subTitle: string
  totalAmount: number
  approvedRequest: number
  fullSize?: boolean
  icon?: keyof typeof Icon
  onClick?: (request?: any) => void
  notLink?: boolean
  iconColor: string
  titleColor: string
  chipColor: string
}

export const ClaimCard = (props: ClaimCardProps) => {
  const {
    totalAmount = 0,
    approvedRequest,
    subTitle,
    title,
    type,
    fullSize = false,
    icon: iconName,
    onClick,
    name,
    notLink,
    iconColor,
    titleColor,
    chipColor,
  } = props

  if (fullSize)
    return (
      <Grid item xs={12} mb="8px">
        <Link to="/claim-detail" onClick={onClick} style={{ pointerEvents: notLink ? "none" : "" }}>
          <Container fullsize>
            <ContainerFlex js="space-between" row al="center">
              <ContainerFlex js="flex-end" row al="center">
                <IconContainer fullsize={fullSize}>
                  <IconBox fullsize>
                    <IconList fullSize name={name} iconName={iconName} iconColor={iconColor} />
                  </IconBox>
                </IconContainer>
                <Box>
                  <Typography title={title} variant="body1" color="Text/Black2">
                    {title}
                  </Typography>
                </Box>
              </ContainerFlex>
              {type && <CategoriesComponent type={type} chipColor={chipColor} titleColor={titleColor} />}
            </ContainerFlex>
            <Footer approvedRequest={approvedRequest} totalAmount={totalAmount} />
          </Container>
        </Link>
      </Grid>
    )
  return (
    <Grid item xs={6}>
      <Link to="/claim-detail" onClick={onClick}>
        <Container fullsize={false} name={name}>
          <IconContainer fullsize={false}>
            <IconBox fullsize={false}>
              <IconList name={name} iconName={iconName} iconColor={iconColor} />
            </IconBox>
          </IconContainer>
          <Box padding="0px 16px">
            <Typography title={title} variant="body1" color="Text/Black2">
              {title}
            </Typography>
          </Box>
        </Container>
      </Link>
    </Grid>
  )
}
