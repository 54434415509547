import MuiTypography, { TypographyProps } from "@material-ui/core/Typography"
import { string } from "prop-types"
import { AppColor } from "theme/app-color"

export const COLOR = {
  "Primary/Blue 1": "#1E293A",
  "Primary/Blue 2": "#233044",
  "Primary Light / Blue 1": "#E8E9EB",
  "Text/Black": "#212121",
  "Text/Dark Grey": "#767676",
  "Text/Light Grey": "#A1A1A1",
  "Text/White": "#FFFFFF",
  "Secondary / Blue": "#2296F3",
  "Secondary Light / Blue": "#E7F4FD",
  "Other/Danger": "#F34336",
  "Other/Warning": "#FF9800",
  "Other/Info": "#376FD0",
  "Other/Success": "#4CAF4F",
  "Other Light/Danger": "#FCECE9",
  "Other Light/Warning": "#FFF4E5",
  "Other Light/Info": "#E7F4FD",
  "Other Light/Success": "#EDF7ED",
  "Background/Dark Grey": "#E0E0E0",
  "Background/Light Grey": "#F6F9FC",
  "Background / White": "#FFFFFF",
  "SecondaryText / Danger": "#601A15",
  "Primary/Brown": "#805D57",
}

interface CustomTypographyProps extends Pick<TypographyProps, Exclude<keyof TypographyProps, "color">> {
  color?: string
}

const Typography = (props: CustomTypographyProps) => {
  const { color, style, ...rest } = props
  const isHexa = color?.startsWith("#")
  const customColor = isHexa ? color : AppColor[color as keyof typeof AppColor]

  const customRestProps = customColor ? { ...rest, style: { ...style, color: customColor } } : rest

  return <MuiTypography {...customRestProps} />
}

export default Typography
