import { Box, Button, Grid, IconButton, Modal, Notification } from "components"
import { ClaimCard } from "components/advance/ClaimCard"
import { StepStatus } from "components/advance/StepStatus"
import { UploadDocumentCard } from "components/advance/UploadDocumentCard"
import { Icon } from "components/commons/Icon"
import Typography from "components/commons/Typography"
import { EnumClaimRequestStatus } from "constants/enums/claim-request-status"
import dayjs from "dayjs"
import { compose, withHooks, withStores } from "enhancers"
import { Field } from "pages/main/claim-detail/form-field"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { AppColor } from "theme/app-color"
import { employeeIsHr, gql, paths } from "utils/helper"
import { ApproveModal } from "./approve-modal"
import { RejectModal } from "./reject-modal"

const DetailStatusContainer = styled("div")`
  position: relative;
  background-color: ${AppColor["Dark Blue/Primary Text"]};
  height: auto;
  padding: 16px;
`

const ClaimDetail = styled("div")`
  position: relative;
  background: ${AppColor["White / White"]};
  height: auto;
  padding: 16px;
  box-shadow: 4px 0px 8px rgba(0, 133, 202, 0.25);
`

const CloseButton = styled(IconButton)`
  color: ${AppColor["White / White"]};
  padding: 0px;
`

const Title = styled("div")`
  margin-top: 24px;
`

const TopBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ActionButton = styled(Button)`
  width: 100%;
`

const DetailBox = styled(Box)`
  margin-top: 16px;
`

const FlexBox = styled(Box)<{ mb?: string; mt?: string }>`
  display: flex;
  margin-bottom: ${(props) => (props.mb ? props.mb : "0px")};
  margin-top: ${(props) => (props.mt ? props.mt : "0px")};
`

const FrontBox = styled(Box)<{ mr?: string }>`
  min-width: 100px;
  margin-right: ${(props) => (props.mr ? props.mr : "0px")};
`

const FooterContainer = styled("div")`
  padding: 16px;
  background-color: ${AppColor["White / White"]};
`

const WarningIcon = styled(Icon)`
  font-size: 16px;
  margin-right: 4px;
`

const WarningBox = styled(Box)<{ mt?: string; mb?: string }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 8px;
  height: 32px;
  background-color: ${AppColor["Pink/Bg"]};
  border-radius: 8px;
  margin-top: ${(props) => (props.mt ? props.mt : "0px")};
  margin-bottom: ${(props) => (props.mb ? props.mb : "0px")};
`

const LightBlueBox = styled(Box)`
  background: ${AppColor["Light Blue/Flat Button Background"]};
  border-radius: 8px;
  padding: 8px;
  margin-top: 16px;
`

const ApprovalPageComponent = (props: any) => (
  <>
    {props.defaultTransferDate && props.isLoaded && (
      <ApproveModal
        title={props.title}
        isOpen={props.isApproveModalOpen}
        onClose={props.handleCloseApproveModal}
        onConfirm={props.handleConfirmApprove}
        amount={props.requestAmount(props.claimRequest)}
        isInsurance={props.isInsurance}
        defaultTransferDate={props.defaultTransferDate}
      />
    )}

    <RejectModal
      title={props.title}
      isOpen={props.isRejectModalOpen}
      onClose={props.handleCloseRejectModal}
      onConfirm={props.handleConfirmReject}
      amount={props.requestAmount(props.claimRequest)}
    />
    <Box style={{ backgroundColor: AppColor["Dark Blue/Primary Text"] }}>
      <Box minHeight={200} minWidth={357} maxWidth={752} overflow="hidden" mx="auto">
        <DetailStatusContainer>
          <TopBox>
            <Typography variant="h2" color="White / White">
              รายละเอียดการเบิก
            </Typography>
            <Link to="/claim-requests">
              <CloseButton>
                <Icon name="Close" style={{ fontSize: "24px" }} />
              </CloseButton>
            </Link>
          </TopBox>
          <DetailBox>
            <FlexBox mb="8px">
              <FrontBox mr="16px">
                <Typography variant="body1" color="White / White">
                  ผู้เบิก
                </Typography>
              </FrontBox>
              <Typography variant="body1" color="White / White">
                {props.createdBy}
              </Typography>
            </FlexBox>
            <FlexBox mb="8px">
              <FrontBox mr="16px">
                <Typography variant="body1" color="White / White">
                  เลขอ้างอิง
                </Typography>
              </FrontBox>
              <Typography variant="body1" color="White / White">
                {props.claimId}
              </Typography>
            </FlexBox>
            <FlexBox mb="8px">
              <FrontBox mr="16px">
                <Typography variant="body1" color="White / White">
                  วันที่เบิก
                </Typography>
              </FrontBox>
              <Typography variant="body1" color="White / White">
                {dayjs(props.createdAt).format("DD/MM/YYYY")}
              </Typography>
            </FlexBox>
            <FlexBox mb="16px">
              <FrontBox mr="16px">
                <Typography variant="body1" color="White / White">
                  สถานะ
                </Typography>
              </FrontBox>
              <StepStatus
                claimStatus={props.claimStatus}
                workflows={props.workflows}
                workflowStep={props.workflowStep}
              />
            </FlexBox>
            <FlexBox>
              <FrontBox mr="16px">
                <Typography variant="body1" color="White / White">
                  แก้ไขล่าสุด
                </Typography>
              </FrontBox>
              <Typography variant="body1" color="White / White">
                {dayjs(props.updatedAt).format("DD/MM/YYYY")}
              </Typography>
            </FlexBox>
          </DetailBox>
          {props.claimStatus === EnumClaimRequestStatus.APPROVED && (
            <LightBlueBox>
              <FlexBox mb="4px">
                <FrontBox mr="8px">
                  <Typography variant="h4" color="Text/Blue">
                    โอนเข้า
                  </Typography>
                </FrontBox>
                <Typography variant="h4" color="Text/Blue">
                  {props.isInsurance ? "บัญชีส่วนตัว" : "บัญชีเงินเดือน"}
                </Typography>
              </FlexBox>
              <FlexBox>
                <FrontBox mr="8px">
                  <Typography variant="subtitle1" color="Text/Gray Preview"></Typography>
                </FrontBox>
                <Typography variant="subtitle1" color="Text/Gray Preview">
                  {props.isInsurance
                    ? "ส่งเอกสารการเคลมให้กับบริษัทประกันเมื่อ " +
                      dayjs(props.transferDate).format("DD/MM/YYYY") +
                      " เงินจะเข้าบัญชีภายใน 15-20 วันทำการ"
                    : "ภายในวันที่ " + dayjs(props.transferDate).format("DD/MM/YYYY")}
                </Typography>
              </FlexBox>
            </LightBlueBox>
          )}
          {props.claimStatus === EnumClaimRequestStatus.REJECTED && (
            <LightBlueBox>
              <FlexBox>
                <FrontBox mr="8px">
                  <Typography variant="h4" color="Text/Blue">
                    เหตุผลที่ปฏิเสธ
                  </Typography>
                </FrontBox>
                <Typography variant="h4" color="Text/Black2">
                  {props.rejectedReason}
                </Typography>
              </FlexBox>
            </LightBlueBox>
          )}
        </DetailStatusContainer>
      </Box>
    </Box>
    <Box minHeight={200} minWidth={357} maxWidth={752} overflow="hidden" mx="auto">
      <ClaimDetail>
        {props.currentUserIsHr && props.isInstead && (
          <>
            <WarningBox mt="8px" mb="24px">
              <WarningIcon name="Warning" htmlColor={AppColor["Text/Primary Pink"]}></WarningIcon>
              <Typography variant="body2" color="Text/Primary Pink">
                กำลังทำรายการแทนพนักงานคนอื่น
              </Typography>
            </WarningBox>
          </>
        )}
        <Box mt="8px">
          <ClaimCard
            title={props.title}
            type={props.section}
            name={props.name}
            subTitle={props.subTitle}
            approvedRequest={props.approvedCount}
            totalAmount={props.approvedAmount}
            icon={props.icon}
            iconColor={props.iconColor}
            titleColor={props.titleColor}
            chipColor={props.chipColor}
            fullSize
            notLink
          />
        </Box>
        <Title>
          <Typography variant="h3">ตรวจสอบรายละเอียด</Typography>
        </Title>
        <Grid container style={{ paddingTop: 16 }}>
          <Grid item xs={12} key="select-employee">
            <Typography variant="body1" color="Text/Gray Preview">
              ผู้ขอเบิก
            </Typography>
            <Field isPreview={true} component="SELECT" label="" name="requester" value={props.requesterValues} />
          </Grid>
          {props.inputs?.map((input: any, ind: number) => (
            <Grid item xs={12} key={`${input.name} ${ind}`} style={{ paddingTop: 16 }}>
              <Typography variant="body1" color="Text/Gray Preview">
                {input.title}
              </Typography>
              <Field
                isPreview={true}
                value={input.value}
                component={input.type}
                label=""
                name={input.name}
                options={input.options}
                unit={input.unit}
              />
            </Grid>
          ))}
        </Grid>

        {props.documents.length > 0 && (
          <UploadDocumentCard
            documents={props.documents}
            onChange={props.handleFilesChange}
            canDelete={false}
            isPreview={true}
            mb="8px"
          />
        )}
      </ClaimDetail>
      {props.showFooter && (
        <FooterContainer>
          <Typography variant="h3" color="Text/Black2">
            ผลการพิจารณา
          </Typography>
          <FlexBox mt="16px" mb="8px">
            <ActionButton
              variant="outlined"
              color="primary"
              onClick={props.handleOpenRejectModal}
              mr="16px"
              disabled={props.disabledRejectButton}
            >
              ไม่อนุมัติ
            </ActionButton>
            <ActionButton
              onClick={props.handleOpenApproveModal}
              variant="contained"
              color="primary"
              disabled={props.disabledApproveButton}
            >
              อนุมัติ
            </ActionButton>
          </FlexBox>
        </FooterContainer>
      )}
    </Box>
  </>
)

const API = {
  GET_CLAIM_REQUEST_CONFIGS: gql`
    query GET_CLAIM_REQUEST_CONFIGS($employeeId: String!) {
      claimRequestConfigs(employeeId: $employeeId) {
        usage
      }
    }
  `,
  GET_HISTORY_DETAIL: gql`
    query GET_HISTORY_DETAIL($id: String!) {
      claimRequestHistoryDetail(id: $id) {
        workflowSeq
        id
        type
        status
        config
        info
        employeeId
        employee
        createdAt
        updatedAt
        createdBy
        referenceId
        rejectedReason
        transferDate
        canceled
        currentWorkflow
      }
    }
  `,
  APPROVE_REQUEST: gql`
    mutation APPROVE_REQUEST(
      $requestId: String!
      $isApprove: Boolean!
      $createdBy: String!
      $rejectedReason: String
      $transferDate: DateTime
      $seq: Int!
    ) {
      approveClaimRequest(
        input: {
          requestId: $requestId
          isApprove: $isApprove
          createdBy: $createdBy
          rejectedReason: $rejectedReason
          transferDate: $transferDate
          seq: $seq
        }
      ) {
        workflowSeq
        id
        type
        status
        config
        info
        createdAt
      }
    }
  `,
}

const enhancer = compose(
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
  })),
  withHooks((props: any, hooks: any) => {
    const { currentUserInfo } = props
    const { useParams, useQuery, useCallback, useEffect, useLazyQuery, useState, useMutation, useMemo } = hooks
    const { id } = useParams()
    const { data, refetch, loading: detailLoading } = useQuery(API.GET_HISTORY_DETAIL, {
      variables: { id },
    })
    const [approveRequest, { error: approveError }] = useMutation(API.APPROVE_REQUEST)
    const [usage, setUsage] = useState()
    const [disabledApproveButton, setDisabledApproveButton] = useState(false)
    const [disabledRejectButton, setDisabledRejectButton] = useState(false)
    const [refetchConfig] = useLazyQuery(API.GET_CLAIM_REQUEST_CONFIGS, {
      onCompleted: (dataConfig: any) => {
        const current = dataConfig.claimRequestConfigs.usage.find(
          (res: any) => res.name === data?.claimRequestHistoryDetail.info.name,
        )
        setUsage(current)
      },
      fetchPolicy: "network-only",
    })

    const [isApproveModalOpen, setIsApproveModalOpen] = useState(false)
    const [isRejectModalOpen, setIsRejectModalOpen] = useState(false)
    const [workflowStep, setWorkflowStep] = useState(0)
    const currentUser = useMemo(() => {
      return currentUserInfo.employee
    }, [currentUserInfo])

    const currentUserIsHr = useMemo(() => employeeIsHr(currentUser.role), [currentUser])

    const approveParams = useMemo(
      () => ({
        requestId: id,
        createdBy: currentUser?.id,
      }),
      [id, currentUser],
    )

    const nextActionWorkflow = useMemo(() => {
      const workflows = data?.claimRequestHistoryDetail.workflowSeq
      const currentWorkflow = workflows?.find(
        (flow: any) => flow.seq === data?.claimRequestHistoryDetail.currentWorkflow + 1,
      )
      return currentWorkflow
    }, [data])

    const currentUserCanAction = useMemo(() => {
      return nextActionWorkflow?.possibleApproverIds.includes(currentUser?.id)
    }, [nextActionWorkflow, currentUser])

    const requestRejected = useMemo(
      () =>
        data?.claimRequestHistoryDetail.workflowSeq.filter(
          (flow: any) => flow.status === EnumClaimRequestStatus.REJECTED,
        ).length > 0,
      [data],
    )

    const requestApproved = useMemo(() => data?.claimRequestHistoryDetail.status === EnumClaimRequestStatus.APPROVED, [
      data,
    ])

    const showFooter = useMemo(() => {
      if (requestApproved || requestRejected || !currentUserCanAction) return false
      return true
    }, [requestApproved, requestRejected, currentUserCanAction])

    const approvalSection = useMemo(() => {
      if (requestRejected)
        return {
          label: "ปฏิเสธเมื่อ",
          approvalAt: dayjs(data?.claimRequestHistoryDetail.approvalAt).format("DD/MM/YYYY"),
          detailLabel: "เหตุผล",
          detail: data?.claimRequestHistoryDetail.rejectedReason,
        }
      if (requestApproved)
        return {
          label: "อนุมัติเมื่อ",
          approvalAt: dayjs(data?.claimRequestHistoryDetail.approvalAt).format("DD/MM/YYYY"),
          detailLabel: "โอนเข้า",
          detail: "บัญชีเงินเดือน",
          sub: `(${dayjs(data?.claimRequestHistoryDetail.transferDate).format("DD/MM/YYYY")})`,
        }
      return undefined
    }, [requestRejected, data, requestApproved])

    const employee = useMemo(() => data?.claimRequestHistoryDetail.employee, [data])

    const hasFile = useMemo(() => data?.claimRequestHistoryDetail.info.documents.filter((doc: any) => doc.file) > 0, [
      data,
    ])

    const isInstead = useMemo(
      () => data?.claimRequestHistoryDetail.employee.id !== data?.claimRequestHistoryDetail.createdBy.id,
      [data],
    )

    const defaultTransferDate = useMemo(() => {
      if (data?.claimRequestHistoryDetail.transferDate) return dayjs(data?.claimRequestHistoryDetail.transferDate)
      return dayjs()
    }, [data])

    const requestAmount = useCallback((history: any) => {
      let amount = 0
      history?.info?.inputs.forEach((input: any) => {
        if (input.name === history.info.amountFieldName) {
          amount = input.value
        }
      })
      return amount
    }, [])

    const isInsurance = useMemo(
      () =>
        data?.claimRequestHistoryDetail.info.section === "ประกันกลุ่ม" ||
        data?.claimRequestHistoryDetail.info.section === "ประกันสังคม",
      [data],
    )

    const handleClosePage = useCallback(() => {
      paths.claimRequestsPath().push()
    }, [])

    const handleCloseErrorModal = useCallback((confirmProps: any) => {
      confirmProps.close()
      paths.claimRequestsPath().push()
      setDisabledApproveButton(false)
      setDisabledRejectButton(false)
    }, [])

    const handleConfirmApprove = useCallback(
      async (date?: string) => {
        // console.log("confirm")

        try {
          setDisabledApproveButton(true)
          setDisabledRejectButton(true)
          let params: {
            isApprove: boolean
            requestId: string
            createdBy: string
            seq: number
            transferDate?: string
          } = {
            isApprove: true,
            seq: nextActionWorkflow.seq,
            ...approveParams,
          }
          if (date) params.transferDate = date
          await approveRequest({ variables: { ...params } })
          Notification.success("บันทึกข้อมูลแล้ว")
          paths.claimRequestsPath().push()
          setDisabledApproveButton(false)
          setDisabledRejectButton(false)
        } catch (e: any) {
          if (e) {
            Modal.alert({
              className: "deleteConFirmModal",
              title: "",
              children: (
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                  <Box mb="16px">
                    <Icon fontSize="large" htmlColor={AppColor["Text/Primary Pink"]} name="Warning"></Icon>
                  </Box>
                  <Box mb="16px">
                    <Typography variant="body1" color="Text/Black2">
                      {e.message}
                    </Typography>
                  </Box>
                </Box>
              ),
              okButtonLabel: "ตกลง",
              okButtonVariant: "contained",
              okButtonColor: "primary",
              buttonWidth: "100%",
              onOk: handleCloseErrorModal,
            })
          }
        }
      },
      [handleCloseErrorModal, approveRequest, approveParams, nextActionWorkflow],
    )
    const handleOpenApproveModal = useCallback(() => {
      // const workflowSeq = data?.claimRequestHistoryDetail.workflowSeq
      // const isLastSeq = workflowSeq[workflowSeq.length - 1].seq === nextActionWorkflow.seq
      if (currentUserIsHr) {
        setIsApproveModalOpen(true)
      } else {
        handleConfirmApprove()
      }
    }, [currentUserIsHr, handleConfirmApprove])

    const handleCloseApproveModal = useCallback(() => {
      setIsApproveModalOpen(false)
    }, [])

    const handleOpenRejectModal = useCallback(() => {
      setIsRejectModalOpen(true)
    }, [])
    const handleCloseRejectModal = useCallback(() => {
      setIsRejectModalOpen(false)
    }, [])
    const handleConfirmReject = useCallback(
      async (reason: string) => {
        try {
          setDisabledApproveButton(true)
          setDisabledRejectButton(true)
          await approveRequest({
            variables: { isApprove: false, seq: nextActionWorkflow.seq, rejectedReason: reason, ...approveParams },
          })
          Notification.success("บันทึกข้อมูลแล้ว")
          paths.claimRequestsPath().push()
          setDisabledApproveButton(false)
          setDisabledRejectButton(false)
        } catch (e: any) {
          if (e) {
            console.log(e.message)
            Modal.alert({
              className: "deleteConFirmModal",
              title: "",
              children: (
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                  <Box mb="16px">
                    <Icon fontSize="large" htmlColor={AppColor["Text/Primary Pink"]} name="Warning"></Icon>
                  </Box>
                  <Box mb="16px">
                    <Typography variant="body1" color="Text/Black2">
                      {e.message}
                    </Typography>
                  </Box>
                </Box>
              ),
              okButtonLabel: "ตกลง",
              okButtonVariant: "contained",
              okButtonColor: "primary",
              buttonWidth: "100%",
              onOk: handleCloseErrorModal,
            })
          }
        }
      },
      [handleCloseErrorModal, approveRequest, approveParams, nextActionWorkflow],
    )

    useEffect(() => {
      const claimUser = data?.claimRequestHistoryDetail.employee
      if (claimUser) {
        refetchConfig({
          variables: { employeeId: claimUser.id },
        })
      }
    }, [data, refetchConfig])

    useEffect(() => {
      refetch()
      if (data?.claimRequestHistoryDetail.workflowSeq) {
        const workflows = data?.claimRequestHistoryDetail.workflowSeq
        const last = workflows[workflows.length - 1]
        if (nextActionWorkflow) {
          const nextActionIndex = workflows.findIndex(
            (wf: any) => JSON.stringify(wf) === JSON.stringify(nextActionWorkflow),
          )
          setWorkflowStep(nextActionIndex)
        } else if (last) {
          setWorkflowStep(workflows.length)
        }
      }
    }, [data, nextActionWorkflow, refetch])

    const isLoaded = useMemo(() => !detailLoading, [detailLoading])

    return {
      handleClosePage,

      handleOpenApproveModal,
      handleCloseApproveModal,
      isApproveModalOpen,
      handleConfirmApprove,

      handleOpenRejectModal,
      handleCloseRejectModal,
      isRejectModalOpen,
      handleConfirmReject,

      claimId: data?.claimRequestHistoryDetail.referenceId,
      createdBy:
        data?.claimRequestHistoryDetail.createdBy.firstName + " " + data?.claimRequestHistoryDetail.createdBy.lastName,
      createdAt: data?.claimRequestHistoryDetail.createdAt,
      updatedAt: data?.claimRequestHistoryDetail.updatedAt,
      inputs: data?.claimRequestHistoryDetail.info.inputs,
      documents: data?.claimRequestHistoryDetail.info.documents.filter((doc: any) => doc.file) || [],
      title: data?.claimRequestHistoryDetail.info.claimCard.title,
      subTitle: data?.claimRequestHistoryDetail.info.claimCard.subTitle,
      icon: data?.claimRequestHistoryDetail.info.claimCard.icon,
      iconColor: data?.claimRequestHistoryDetail.info.claimCard.iconColor,
      titleColor: data?.claimRequestHistoryDetail.info.claimCard.titleColor,
      chipColor: data?.claimRequestHistoryDetail.info.claimCard.chipColor,
      section: data?.claimRequestHistoryDetail.info.section,
      name: data?.claimRequestHistoryDetail.info.name,
      approvedAmount: usage?.approvedAmount,
      approvedCount: usage?.approvedCount,
      claimStatus: data?.claimRequestHistoryDetail.status,
      currentUser,

      workflows: data?.claimRequestHistoryDetail.workflowSeq || [],
      workflowStep: requestRejected ? (workflowStep - 1 < 0 ? 0 : workflowStep - 1) : workflowStep,

      showFooter,

      requesterValues: employee ? employee.firstName + " " + employee.lastName : undefined,
      approvalSection,
      hasFile,
      requestAmount,
      claimRequest: data?.claimRequestHistoryDetail,
      transferDate: data?.claimRequestHistoryDetail.transferDate || null,
      rejectedReason: data?.claimRequestHistoryDetail.rejectedReason || null,
      currentUserIsHr,
      isInstead,
      isInsurance,
      error: approveError,
      defaultTransferDate,

      disabledApproveButton,
      disabledRejectButton,
      isLoaded,
    }
  }),
)

const ApprovalPage = enhancer(ApprovalPageComponent)

export default ApprovalPage
