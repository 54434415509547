import { gql } from "@apollo/client"
import FilterAltIcon from "assets/icon/filter_alt.svg"
import { Box, Button, Hidden, IconButton } from "components"
import { Icon } from "components/commons/Icon"
import Typography from "components/commons/Typography"
import { EnumClaimRequestStatus } from "constants/enums/claim-request-status"
import { compose, withHooks, withStores } from "enhancers"
import { PropsWithChildren, useCallback, useMemo } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { AppColor } from "theme/app-color"
import { theme } from "../constants"

const TitleContainer = styled("div")<Partial<TitleProps>>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.centered ? "center" : "flex-start")};
  background-color: ${(props) =>
    props.titleBg ? props.titleBg : props.titleDark ? AppColor["Gray/Bg"] : AppColor["Dark Blue/Primary Text"]};
  padding: 0px 16px 0px 0px;
`

const PersonalRemain = styled.div`
  width: calc(100% - 8px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid;
  padding: 4px;
`

const ListIconContainer = styled("div")`
  position: absolute;
  top: 6px;
  right: 4px;
`

const CustomIconList = styled(Icon)`
  font-size: 20px;
`

const TitleText = styled.div<{ haveBackButton?: boolean }>`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  /*white-space: nowrap;*/
  overflow: hidden;
  text-overflow: ellipsis;
  padding: ${(props) => (props.haveBackButton ? "14px 16px 14px 0px" : "14px 0px 14px 16px")};
`

const ListAmount = styled("div")`
  position: absolute;
  background-color: ${AppColor["Text/Primary Pink"]};
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 12px;
  right: 10px;
  height: 12px;
  width: fit-content;
  padding: 0px 3.5px;
  z-index: 2;
`

const FilteredMark = styled("div")`
  position: absolute;
  background-color: ${AppColor["Text/Primary Pink"]};
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 9px;
  right: 8px;
  height: 8px;
  padding: 0px 3.5px;
  z-index: 2;
`

interface TitleProps {
  title: string
  centered?: boolean
  personalRemain?: number
  onListClickTo?: string
  onBack?: () => void
  titleDark?: boolean
  titleBg?: string
  showFilter?: boolean
  openFilterModal?: void
  hasFilter?: boolean
}

const API = {
  CHECK_HAVE_LIST: gql`
    query CHECK_HAVE_LIST {
      checkHaveList
    }
  `,
  GET_CLAIM_REQUEST_LIST: gql`
    query GET_CLAIM_REQUEST_LIST($status: String!) {
      claimRequestList(input: { status: $status }) {
        id
        status
      }
    }
  `,
}

const TitleEnhancer = compose(
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
  })),
  withHooks((props: any, hooks: any) => {
    const { currentUserInfo, onListClickTo } = props
    const { useState, useLazyQuery, useQuery, useEffect } = hooks
    const currentUser = currentUserInfo.employee

    const [showList, setShowList] = useState(false)
    const [fetchHaveList] = useLazyQuery(API.CHECK_HAVE_LIST, {
      onCompleted(data: any) {
        setShowList(data.checkHaveList)
      },
      fetchPolicy: "network-only",
    })
    const filteredStatus = EnumClaimRequestStatus.WAITING
    const { data } = useQuery(API.GET_CLAIM_REQUEST_LIST, {
      variables: { status: filteredStatus },
      fetchPolicy: "network-only",
    })

    useEffect(() => {
      if (onListClickTo) fetchHaveList()
    }, [onListClickTo, fetchHaveList])

    return { ...props, currentUser, showList, listAmount: data?.claimRequestList.length || 0 }
  }),
)

const TitleComponent = (props: any) => {
  const {
    title,
    centered,
    personalRemain,
    onListClickTo,
    onBack,
    titleDark = false,
    showList,
    titleBg,
    showFilter,
    openFilterModal,
    listAmount,
    hasFilter,
  } = props

  return (
    <>
      {title !== "" ? (
        <TitleContainer centered={centered} titleDark={titleDark} titleBg={titleBg}>
          {onBack && (
            <IconButton
              onClick={onBack}
              style={{ color: titleDark ? AppColor["Text/Black2"] : AppColor["White / White"] }}
            >
              <CustomIconList name="ChevronLeft" />
            </IconButton>
          )}
          <TitleText haveBackButton={onBack ? true : false}>
            <Typography variant="h2" color={titleDark ? "Gray/Primary Text" : "White / White"} noWrap>
              {title}
            </Typography>
          </TitleText>
          {showList && onListClickTo && (
            <>
              {listAmount > 0 && (
                <Link to={onListClickTo}>
                  <ListAmount>
                    <Typography color="White / White" style={{ fontSize: "8px", fontWeight: 400, lineHeight: "16px" }}>
                      {listAmount > 99 ? "99+" : listAmount}
                    </Typography>
                  </ListAmount>
                  <ListIconContainer>
                    <IconButton style={{ color: titleDark ? AppColor["Text/Black2"] : AppColor["White / White"] }}>
                      <CustomIconList name="DescriptionOutlined" fontSize="small" />
                    </IconButton>
                  </ListIconContainer>
                </Link>
              )}
            </>
          )}
          {showFilter && (
            <ListIconContainer>
              {hasFilter && <FilteredMark />}
              <IconButton
                style={{ color: titleDark ? AppColor["Text/Black2"] : AppColor["White / White"] }}
                onClick={openFilterModal}
              >
                <img src={FilterAltIcon} style={{ width: "auto", height: "auto" }} alt="" />
              </IconButton>
            </ListIconContainer>
          )}
        </TitleContainer>
      ) : (
        <></>
      )}

      {personalRemain ? (
        <PersonalRemain>
          <Typography variant="body2">คะแนนที่มีอยู่ {personalRemain} คะแนน</Typography>
        </PersonalRemain>
      ) : (
        <></>
      )}
    </>
  )
}

const Title = TitleEnhancer(TitleComponent)

const FooterContainer = styled.div``

const FooterButton = styled(Button)`
  width: 50%;
`

interface FooterProps {
  onClick?: (props?: any) => void
  txt?: string
  to?: string
}

const Footer = (props: FooterProps) => {
  const { onClick, txt = "ถัดไป", to } = props

  const FooterButtonEle = () => <FooterButton onClick={onClick}>{txt}</FooterButton>
  return (
    <FooterContainer>
      {to ? (
        <Link to={to}>
          <FooterButtonEle />
        </Link>
      ) : (
        <FooterButtonEle />
      )}
    </FooterContainer>
  )
}

const CustomBox = styled(Box)<{ ["border-top"]?: string; bgcolor?: string; ["margin-top"]?: string }>`
  position: relative;
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : theme.color.white)};
  border-top: ${(props) => (props["border-top"] ? props["border-top"] : "0px")};
  margin-top: ${(props) => (props["margin-top"] ? `-${props["margin-top"]}` : "0px")}; ;
`

const BlueContainer = styled(Box)<{ ["blue-container-height"]?: string }>`
  position: relative;
  background: ${AppColor["Dark Blue/Primary Text"]};
  height: ${(props) => (props["blue-container-height"] ? props["blue-container-height"] : "0px")};
`
const BgBlueContainer = styled(Box)<{ ["blue-container-height"]?: string }>`
  position: relative;
  background: ${AppColor["Dark Blue/Primary Text"]};
  height: ${(props) => (props["blue-container-height"] ? props["blue-container-height"] : "0px")};
`

const ChildrenContainer = styled.div<{ childrenMarginTop?: string; childrenPadding?: string }>`
  position: relative;
  padding: ${(props) => (props.childrenPadding ? props.childrenPadding : "16px")};
  height: 100%;
  margin-top: ${(props) => (props.childrenMarginTop ? props.childrenMarginTop : "0px")};
`

interface PageContentProps extends PropsWithChildren<unknown> {
  title: string
  titleCentered?: boolean
  personalRemain?: number
  onFooterClick?: (props?: any) => void
  footerButtonTxt?: string
  to?: string
  onListClickTo?: string
  onBack?: () => void
  titleDark?: boolean
  bgColor?: string
  blueContainerHeight?: string
  childrenMarginTop?: string
  pageBorderTop?: string
  childrenPadding?: string
  titleBg?: string
  showFilter?: boolean
  openFilterModal?: void
  hasFilter?: boolean
}

export const PageContent = (props: PageContentProps) => {
  const {
    title,
    children,
    titleCentered = true,
    personalRemain,
    footerButtonTxt,
    onFooterClick,
    to,
    onListClickTo,
    onBack,
    titleDark = false,
    bgColor = AppColor["White / White"],
    blueContainerHeight,
    childrenMarginTop,
    pageBorderTop,
    childrenPadding,
    titleBg,
    showFilter,
    openFilterModal,
    hasFilter,
  } = props

  const isShowFooterButton = useMemo(() => !(to || onFooterClick), [to, onFooterClick])

  const handleFooterClick = useCallback(() => {
    if (to) {
      if (onFooterClick) return onFooterClick
      return undefined
    }
  }, [to, onFooterClick])

  const bgHeightBlue = blueContainerHeight ? `${parseInt(blueContainerHeight, 10) + 56}px` : "0px"

  return (
    <div style={{ backgroundColor: bgColor }}>
      <BgBlueContainer blue-container-height={bgHeightBlue}></BgBlueContainer>
      <CustomBox
        minWidth={357}
        maxWidth={784}
        overflow="hidden"
        mx="auto"
        bgcolor={bgColor}
        border-top={pageBorderTop}
        margin-top={bgHeightBlue}
      >
        <div>
          <Title
            onBack={onBack}
            onListClickTo={onListClickTo}
            title={title}
            centered={titleCentered}
            personalRemain={personalRemain}
            titleDark={titleDark}
            titleBg={titleBg}
            showFilter={showFilter}
            openFilterModal={openFilterModal}
            hasFilter={hasFilter}
          />
          <BlueContainer blue-container-height={blueContainerHeight}></BlueContainer>

          <ChildrenContainer childrenMarginTop={childrenMarginTop} childrenPadding={childrenPadding}>
            <>{children}</>
          </ChildrenContainer>
        </div>
        <Hidden when={isShowFooterButton}>
          <Box minHeight={200} minWidth={357} maxWidth={752} overflow="hidden" mx="auto">
            <Footer onClick={handleFooterClick} txt={footerButtonTxt} to={to} />
          </Box>
        </Hidden>
      </CustomBox>
    </div>
  )
}
