import { compose, withFormik, withHooks, withStores } from "enhancers"
import { PageContent, PageFooter } from "layouts"
import { Box, Button, Grid } from "components"
import { ClaimCard } from "components/advance/ClaimCard"
import { Icon } from "components/commons/Icon"
import Typography from "components/commons/Typography"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { employeeIsHr, gql } from "utils/helper"
import { AppColor } from "theme/app-color"

const ContainerFlex = styled.div<{ js: string; row?: boolean }>`
  display: flex;
  justify-content: ${(props) => props.js};
  align-items: center;
  flex-direction: ${(props) => (props.row ? "row" : "column")};
`

const CustomIconList = styled(Icon)`
  font-size: 20px;
  margin-left: 8px;
`

const CurrentUser = styled.div`
  width: 100%;
  margin-bottom: 24px;
`

const LandingComponent = (props: any) => (
  <>
    <PageContent
      title="E-claim"
      titleCentered
      onListClickTo="/claim-requests"
      titleDark={false}
      blueContainerHeight="228px"
      childrenMarginTop="-219px"
      // childrenPadding="4px"
    >
      <Grid container>
        <CurrentUser>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" justifyContent="" flexDirection="column">
              <Typography variant="h1" color="White / White">
                สวัสดี
              </Typography>
              <Typography variant="h1" color="White / White">
                {props.currentUserInfo?.employee.firstName + " " + props.currentUserInfo?.employee.lastName}
              </Typography>
            </Box>
            <Box display="flex" alignSelf="flex-start">
              <Link to={"/history"} style={{ display: "flex", alignItems: "center", color: AppColor["White / White"] }}>
                <Icon name="Restore" style={{ fontSize: "14px", marginRight: "2px" }} />
                <Typography variant="subtitle1" color="White / White">
                  ประวัติการเบิก
                </Typography>
              </Link>
            </Box>
          </Box>
        </CurrentUser>
        <div>
          <ContainerFlex js="center" row>
            <Box mr="8px">
              <Typography variant="h3" color="White / White">
                สวัสดิการพนักงาน
              </Typography>
            </Box>
            <Typography variant="subtitle1" color="White / White">
              ( {props.length} รายการ )
            </Typography>
          </ContainerFlex>
        </div>
        <Grid container mt={4}>
          {props.claimRequestConfigs.map((data: any, ind: number) => {
            if (ind < 4)
              return (
                <ClaimCard
                  key={data.name}
                  onClick={() => props.handleClickRequest(data)}
                  type={data.section}
                  name={data.name}
                  subTitle={data.claimCard.subTitle}
                  approvedRequest={data.approvedCount}
                  totalAmount={data.approvedAmount}
                  title={data.claimCard.title}
                  icon={data.claimCard.icon}
                  iconColor={data.claimCard.iconColor}
                  titleColor={data.claimCard.titleColor}
                  chipColor={data.claimCard.chipColor}
                  fullSize
                />
              )
          })}
        </Grid>
      </Grid>
      <Link to="/claim-list" style={{ textDecoration: "none" }}>
        <Button width={`100%`} color="primary" variant="outlined">
          ดูทั้งหมด
          {<CustomIconList name="ChevronRight" />}
        </Button>
      </Link>
      <Box style={{ height: "82px" }}></Box>
    </PageContent>
    <PageFooter page="home" isHr={props.userIsHr} />
  </>
)

export const API = {
  GET_CLAIM_REQUEST_CONFIGS: gql`
    query GET_CLAIM_REQUEST_CONFIGS($employeeId: String!) {
      claimRequestConfigs(employeeId: $employeeId) {
        workFlowConfigs
        master
        layouts
        requests
        usage
      }
    }
  `,
}

const enhancer = compose(
  withFormik({}),
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
  })),
  withHooks((props: any, hooks: any) => {
    const { currentUserInfo } = props

    const { useQuery, useEffect, useCallback, useMemo } = hooks
    const { data, refetch } = useQuery(API.GET_CLAIM_REQUEST_CONFIGS, {
      variables: {
        employeeId: currentUserInfo.employee.id,
      },
      nextFetchPolicy: "network-only",
    })

    const config = useMemo(() => data?.claimRequestConfigs, [data])

    const storeItem = useCallback(() => {
      console.log("store", config)
      localStorage.setItem("workflow", JSON.stringify(config.workFlowConfigs))
      localStorage.setItem("master", JSON.stringify(config.master))
    }, [config])

    const userIsHr = useMemo(() => employeeIsHr(currentUserInfo.employee.role), [currentUserInfo])

    const handleClickRequest = (value: any) => {
      localStorage.setItem("selected_request", JSON.stringify(value))
    }

    const claimRequestConfigs = useMemo(() => {
      let array: any[] = []
      const claimList = ["weddingCongratulation", "childbirthCongratulation", "employeeFamilyPassingAway", "domestic"]
      if (data) {
        claimList.map((claim) => {
          const requestClaim = data?.claimRequestConfigs.requests.filter((request: any) => request.name === claim)
          array.push(requestClaim[0])
        })
      }
      return array
    }, [data])

    useEffect(() => {
      localStorage.removeItem("selected_request")
      refetch()
    }, [refetch])

    useEffect(() => {
      if (config) {
        storeItem()
      }
    }, [config, storeItem])

    return {
      claimRequestConfigs,
      length: data?.claimRequestConfigs.requests.length,
      handleClickRequest,
      currentUserInfo,
      userIsHr,
    }
  }),
)

export default enhancer(LandingComponent)
