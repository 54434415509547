import { gql, useLazyQuery } from "@apollo/client"
import { ReactComponent as ExportFile } from "assets/icon/export_file.svg"
import FilterAltIcon from "assets/icon/filter_alt.svg"
import {
  Box,
  Button,
  DatePicker,
  ExcelGenerator,
  Field,
  Form,
  Grid,
  Select,
  Table,
  TextField,
  Typography,
} from "components"
import { EnumClaimRequestStatus } from "constants/enums/claim-request-status"
import { ReportType } from "constants/objects/report-type"
import { compose, withFormik, withHooks, withStores } from "enhancers"
import { PageContent, PageFooter } from "layouts"
import styled from "styled-components"
import { AppColor } from "theme/app-color"
import { employeeIsHr, formatDate, paths } from "utils/helper"
import { ReportFilterModal } from "./ReportFilterModal"

const Title = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const FilterBox = styled(Box)`
  margin-top: 24px;
  margin-bottom: 24px;
  background: ${AppColor["White / White"]};
  border-radius: 8px;
  padding: 16px;
  border: 1px solid ${AppColor["Gray/Subtle Light"]};
`

const ResetFilterButton = styled(Box)`
  display: flex;
  align-items: center;
  text-decoration: underline;
  color: ${AppColor["Text/Blue"]};
  :hover {
    cursor: pointer;
  }
`

const ClaimTable = styled(Table)<{}>(() => ({
  backgroundColor: AppColor["White / White"],
  "& .MuiDataGrid-columnHeader": {
    background: AppColor["Green/Button Background"],
    borderRight: `1px solid ${AppColor["Green/Primary Text"]}`,
  },
  "& .MuiDataGrid-columnsContainer": {
    border: `1px solid ${AppColor["Green/Primary Text"]}`,
    borderRadius: "8px 8px 0px 0px",
    pointerEvents: "none",
  },
  "& .MuiDataGrid-dataContainer": {
    borderRight: `1px solid ${AppColor["Gray/Card Border, Line"]}`,
  },
  "& .MuiDataGrid-row": {
    borderTop: `1px solid ${AppColor["Green/Primary Text"]}`,
    borderLeft: `1px solid ${AppColor["Gray/Card Border, Line"]}`,
    borderRight: `1px solid ${AppColor["Gray/Card Border, Line"]}`,
    borderBottom: `1px solid ${AppColor["Gray/Card Border, Line"]}`,
  },
  "& .MuiDataGrid-cell": {
    borderRight: `1px solid ${AppColor["Gray/Card Border, Line"]}`,
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-sortIcon": {
    display: "none",
  },
  "& .MuiTablePagination-root": {
    color: AppColor["Text/Black2"],
  },
  "& .MuiTablePagination-select.MuiSelect-selectMenu.MuiInputBase-input": {
    border: `1px solid ${AppColor["Gray/Subtle Light"]}`,
  },
  "& .MuiTablePagination-actions": {
    marginLeft: "16px",
  },
  "& .MuiIconButton-root": {
    padding: "8px",
  },
  "& .MuiTypography-root": {
    fontWeight: 500,
  },
}))

const DashboardPage = (props: any) => (
  <>
    <PageContent
      title="E-claim"
      titleCentered
      onListClickTo="/claim-requests"
      blueContainerHeight="228px"
      childrenMarginTop="-228px"
      // childrenPadding="0px"
    >
      <Title mt="8px">
        <Typography variant="h1" color={AppColor["White / White"]}>
          รายการขอเบิก
        </Typography>
        <Button
          color="primary"
          width="176px"
          variant="contained"
          style={{ border: "none" }}
          onClick={props.handleOpenExportReportModal}
        >
          <ExportFile style={{ marginRight: "6px" }} />
          ออกรายงาน
        </Button>
      </Title>
      <FilterBox>
        <Form>
          <Title mb="16px">
            <Box display="flex" alignItems="center">
              <img src={FilterAltIcon} style={{ width: "19px", height: "19px", marginRight: "8px" }} alt="" />
              <Typography variant="h4" color={AppColor["Text/Black2"]}>
                ตัวกรอง
              </Typography>
            </Box>
          </Title>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Box mb={2}>
                <Typography>ช่วงวันที่สร้างรายการ</Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                  <Field
                    component={DatePicker}
                    name="startDate"
                    placeholder="วันที่"
                    fullWidth
                    maxDate={props.values.endDate}
                    onChange={(values: string) => props.setFieldValue("startDate", values)}
                  />
                </Box>
                <Box padding="0px 8px">
                  <Typography variant="body1" color={AppColor["Text/Black2"]}>
                    -
                  </Typography>
                </Box>
                <Box>
                  <Field
                    component={DatePicker}
                    name="endDate"
                    placeholder="วันที่"
                    fullWidth
                    minDate={props.values.startDate}
                    onChange={(values: string) => props.setFieldValue("endDate", values)}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <Box mb={2}>
                    <Typography>สถานะ</Typography>
                  </Box>
                  <Field
                    component={Select}
                    name="status"
                    type="text"
                    placeholder="สถานะ"
                    options={props.claimStatusOptions}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <Box mb={2}>
                    <Typography>เลขอ้างอิง</Typography>
                  </Box>
                  <Field component={TextField} name="referenceId" fullWidth />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={4} mt="8px">
            <Grid item xs={12} sm={6}>
              <Box mb={2}>
                <Typography>ประเภทการเบิก</Typography>
              </Box>
              <Field
                component={Select}
                name="claimTitle"
                type="text"
                placeholder="ประเภทการเบิก"
                options={props.claimTitleOptions}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box mb={2}>
                <Typography>ผู้ขอเบิก</Typography>
              </Box>
              <Field
                component={Select}
                name="employeeId"
                type="text"
                placeholder="ผู้ขอเบิก"
                options={props.employeeOptions}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={4} mt="8px">
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={4} alignItems="center">
                <Grid item xs={6}>
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <ResetFilterButton onClick={props.clearFilter}>
                      <Typography variant="h4">รีเซ็ตตัวกรอง</Typography>
                    </ResetFilterButton>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Button color="primary" variant="outlined" width="100%" onClick={props.handleFilter}>
                    กรอง
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </FilterBox>
      <ClaimTable
        className="claimRequestTable"
        columns={props.columns}
        rows={props.tableData}
        loading={props.loading}
        density="compact"
        autoHeight
        autoDetectInitialFilterOnUrlQueryParams
        onRowClick={props.goToHistoryDetail}
        pageSize={props.pageSize}
        onPageSizeChange={props.onPageSizeChange}
        rowsPerPageOptions={[10, 25, 50]}
      />
      <Box style={{ height: "82px" }}></Box>
    </PageContent>
    <PageFooter page="dashboard" isHr={props.userIsHr} />
    <ReportFilterModal
      isOpen={props.isExportReportModalOpen}
      onConfirm={props.handleClickExportReport}
      onCancel={props.handleCloseExportReportModal}
      defaultStartDate={props.startDate}
      defaultEndDate={props.endDate}
    />
  </>
)

const API = {
  GET_CLAIM_REQUEST_CONFIGS: gql`
    query GET_CLAIM_REQUEST_CONFIGS($employeeId: String!) {
      claimRequestConfigs(employeeId: $employeeId) {
        requests
      }
    }
  `,
  GET_EMPLOYEES: gql`
    query GET_EMPLOYEES {
      getEmployees {
        id
        employeeCode
        firstName
        lastName
      }
    }
  `,
  GET_DASHBOARD_CLAIM_LIST: gql`
    query GET_DASHBOARD_CLAIM_LIST($filters: JSON) {
      dashboardClaimList(input: { filters: $filters }) {
        id
        referenceId
        employee
        status
        config
        createdAt
      }
    }
  `,
  GET_PREPARE_REPORT_DATA: gql`
    query GET_PREPARE_REPORT_DATA($reportType: String!, $startDate: String!, $endDate: String!) {
      prepareReportData(filters: { reportType: $reportType, startDate: $startDate, endDate: $endDate })
    }
  `,
}

const today = new Date(Date.now())

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      startDate: new Date(today.getFullYear(), today.getMonth(), 1),
      endDate: today,
      claimTitle: "all",
      status: "ALL",
    }),
  }),
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
  })),
  withHooks((props: any, hooks: any) => {
    const { currentUserInfo, values, setValues, setFieldValue } = props
    const { useMemo, useCallback, useQuery, useState } = hooks
    const [claimTitleOptions, setClaimTitleOptions] = useState([])
    const [employeeOptions, setEmployeeOptions] = useState([])
    const [pageSize, setPageSize] = useState(10)
    const [isExportReportModalOpen, setIsExportReportModalOpen] = useState(false)

    const [reportType, setReportType] = useState()

    const { loading, data, error, refetch } = useQuery(API.GET_DASHBOARD_CLAIM_LIST, {
      variables: {
        filters: {
          startDate: formatDate(new Date(today.getFullYear(), today.getMonth(), 1), "yyyy-MM-dd"),
          endDate: formatDate(today, "yyyy-MM-dd"),
          claimTitle: "all",
          status: "ALL",
        },
      },
      fetchPolicy: "network-only",
    })

    const [fetchExportData] = useLazyQuery(API.GET_PREPARE_REPORT_DATA, {
      onCompleted: (data) => {
        exportReport(data)
      },
      fetchPolicy: "network-only",
    })

    useQuery(API.GET_EMPLOYEES, {
      onCompleted: (data: any) => {
        const current = data.getEmployees

        const employees: any = []
        current.forEach((item: any) => {
          employees.push({
            label: item.firstName + " " + item.lastName,
            value: item.id,
          })
        })

        setEmployeeOptions(employees)
      },
      fetchPolicy: "network-only",
    })

    useQuery(API.GET_CLAIM_REQUEST_CONFIGS, {
      variables: {
        employeeId: currentUserInfo.employee.id,
      },
      onCompleted: (data: any) => {
        const current = data.claimRequestConfigs.requests

        const claimTitle: any = []
        claimTitle.push({
          label: "ทั้งหมด",
          value: "all",
        })
        current.forEach((item: any) => {
          claimTitle.push({
            label: item.claimCard.title,
            value: item.claimCard.title,
          })
        })

        setClaimTitleOptions(claimTitle)
      },
      fetchPolicy: "network-only",
    })

    const columns = useMemo(() => {
      return [
        { width: 140, field: "referenceId", headerName: "เลขทำรายการ" },
        { width: 220, field: "name", headerName: "ผู้ขอเบิก" },
        {
          width: 220,
          field: "claimTitle",
          headerName: "ประเภทการเบิก",
        },
        { width: 105, field: "status", headerName: "สถานะ" },
        {
          width: 120,
          field: "createdAt",
          headerName: "วันที่สร้าง",
          type: "dateOnly",
        },
      ]
    }, [])

    const MappingStatusLabel = (status: EnumClaimRequestStatus) => {
      switch (status) {
        case EnumClaimRequestStatus.APPROVED:
          return "อนุมัติ"
        case EnumClaimRequestStatus.WAITING:
          return "รออนุมัติ"
        case EnumClaimRequestStatus.REJECTED:
          return "ไม่อนุมัติ"
        case EnumClaimRequestStatus.CANCELED:
          return "ยกเลิก"
        default:
          break
      }
    }

    const tableData = useMemo(() => {
      if (loading || error) {
        return []
      }
      return data?.dashboardClaimList.map((claim: any) => {
        const { id, referenceId, employee, status, config, createdAt } = claim
        const { claimCard } = config

        const name = employee.firstName + " " + employee.lastName

        const claimStatus = MappingStatusLabel(status)

        return {
          id,
          referenceId,
          name,
          status: claimStatus,
          claimTitle: claimCard.title,
          createdAt,
        }
      })
    }, [loading, data, error])

    const claimStatusOptions = useMemo(() => {
      return [
        {
          label: "ทั้งหมด",
          value: "ALL",
        },
        {
          label: "รออนุมัติ",
          value: EnumClaimRequestStatus.WAITING,
        },
        {
          label: "อนุมัติ",
          value: EnumClaimRequestStatus.APPROVED,
        },
        {
          label: "ไม่อนุมัติ",
          value: EnumClaimRequestStatus.REJECTED,
        },
        {
          label: "ยกเลิก",
          value: EnumClaimRequestStatus.CANCELED,
        },
      ]
    }, [])

    const handleFilter = useCallback(async () => {
      const variables = {
        filters: {
          claimType: values.claimTitle,
          startDate: formatDate(values.startDate, "yyyy-MM-dd"),
          endDate: formatDate(values.endDate, "yyyy-MM-dd"),
          status: values.status,
          referenceId: values.referenceId,
          employeeId: values.employeeId,
        },
      }
      console.log(variables)
      await refetch(variables)
    }, [values, refetch])

    const clearFilter = useCallback(async () => {
      const variables = {
        filters: {
          claimType: "all",
          startDate: formatDate(new Date(today.getFullYear(), today.getMonth(), 1), "yyyy-MM-dd"),
          endDate: formatDate(today, "yyyy-MM-dd"),
          status: "ALL",
        },
      }
      await refetch(variables)
      setValues({
        claimTitle: "all",
        startDate: new Date(today.getFullYear(), today.getMonth(), 1),
        endDate: today,
        status: "ALL",
        referenceId: "",
        employeeId: null,
      })
    }, [setValues, refetch])

    const goToHistoryDetail = useCallback((params: any) => {
      const { id } = params
      paths.dashboardDetailPath(id).push()
    }, [])

    const onPageSizeChange = useCallback((newPage: any) => setPageSize(newPage), [])

    const userIsHr = useMemo(() => employeeIsHr(currentUserInfo.employee.role), [currentUserInfo])

    const handleOpenExportReportModal = useCallback(() => {
      setIsExportReportModalOpen(true)
    }, [])

    const handleCloseExportReportModal = useCallback(() => {
      setIsExportReportModalOpen(false)
    }, [])

    const exportDataToPayrollReport = useCallback(
      (data: any) => {
        ExcelGenerator.generate({
          fileName: `${reportType}-${formatDate(new Date(), "yyyyMMddhhmmss")}`,
          columns: [
            {
              field: "no",
              title: "No",
              width: "30px",
            },
            {
              field: "employeeCode",
              title: "Employee ID",
              width: "150px",
            },
            {
              field: "amount",
              title: "Amount",
              width: "100px",
            },
            {
              field: "transCode",
              title: "TransCode",
              width: "150px",
            },
          ],
          data,
        })
      },
      [reportType],
    )

    const exportAllowanceRequisitionReport = useCallback(
      (data: any) => {
        ExcelGenerator.generate({
          fileName: `${reportType}-${formatDate(new Date(), "yyyyMMddhhmmss")}`,
          columns: [
            {
              field: "no",
              title: "No",
              width: "30px",
            },
            {
              field: "employeeCode",
              title: "EMPLOYEE ID",
              width: "150px",
            },
            {
              field: "branch",
              title: "BRANCH",
              width: "200px",
            },
            {
              field: "departmentName",
              title: "DEPARTMENT NAME",
              width: "150px",
            },
            {
              field: "expenseTypeName",
              title: "EXPENSE TYPE NAME",
              width: "200px",
            },
            {
              field: "expenseTypeCode",
              title: "EXPENSE TYPE CODE",
              width: "200px",
            },
            {
              field: "date",
              title: "DATE",
              width: "150px",
            },
            {
              field: "serviceYear",
              title: "SERVICE YEAR",
              width: "150px",
            },
            {
              field: "amount",
              title: "AMOUNT",
              width: "150px",
            },
            {
              field: "wreathAmount",
              title: "WREATH AMOUNT",
              width: "150px",
            },
            {
              field: "totalPaidAmount",
              title: "TOTAL PAID AMOUNT",
              width: "150px",
            },
            {
              field: "diffAmount",
              title: "DIFF AMOUNT",
              width: "150px",
            },
            {
              field: "reasonDiff",
              title: "REASON DIFF",
              width: "150px",
            },
            {
              field: "approveDate",
              title: "APPROVE DATE",
              width: "150px",
            },
            {
              field: "transferDate",
              title: "PAID PERIOD/PAID DATE",
              width: "150px",
            },
            {
              field: "remark",
              title: "REMARK",
              width: "150px",
            },
          ],
          data,
        })
      },
      [reportType],
    )

    const exportReport = useCallback(
      (data: any) => {
        // console.log(data)
        const typeofReport = reportType as keyof typeof ReportType
        switch (typeofReport) {
          case "Allowance Requisition": {
            const exportData = data.prepareReportData.map((data: any) => {
              const { date, approveDate, transferDate } = data
              const dateFormat = date ? formatDate(new Date(date), "dd/MM/yyyy") : ""
              const approveDateFormat = approveDate ? formatDate(new Date(approveDate), "dd/MM/yyyy") : ""
              const transferDateFormat = transferDate ? formatDate(new Date(transferDate), "dd/MM/yyyy") : ""
              return { ...data, date: dateFormat, approveDate: approveDateFormat, transferDate: transferDateFormat }
            })
            exportAllowanceRequisitionReport(exportData)
            break
          }

          default:
            exportDataToPayrollReport(data.prepareReportData)
            break
        }
      },
      [reportType, exportDataToPayrollReport, exportAllowanceRequisitionReport],
    )

    const handleClickExportReport = useCallback(
      async (filter: any) => {
        const { startDate, endDate, format } = filter
        // console.log(format)
        setReportType(format)
        await fetchExportData({
          variables: {
            reportType: format,
            startDate: formatDate(startDate, "yyyy-MM-dd"),
            endDate: formatDate(endDate, "yyyy-MM-dd"),
          },
        })
      },
      [fetchExportData],
    )

    const startDate = useMemo(() => values.startDate, [values])
    const endDate = useMemo(() => values.endDate, [values])

    return {
      columns,
      tableData,
      values,
      setFieldValue,
      claimStatusOptions,
      clearFilter,
      claimTitleOptions,
      handleFilter,
      employeeOptions,
      goToHistoryDetail,
      pageSize,
      onPageSizeChange,
      userIsHr,

      isExportReportModalOpen,

      handleClickExportReport,
      handleOpenExportReportModal,
      handleCloseExportReportModal,

      startDate,
      endDate,
    }
  }),
)

const DashboardComponent = enhancer(DashboardPage)

export default DashboardComponent
