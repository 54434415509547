import qs from "qs"
import { last } from "lodash"
import { history } from "components/common/BrowserRouter"

export class Path extends String {
  newTab = () => {
    window.open(this.toString(), "__blank")
  }

  push = (extendedPath: String = "") => {
    if (history) {
      history.push(`${this.toString()}${extendedPath ?? ""}`)
    }
  }

  reload = () => {
    window.location.href = this.toString()
  }

  makePath = (path: Function): Function => {
    return (...args: any[]) => {
      let pathMethod = typeof path === "string" ? () => path : path
      let absolutePath = null

      if (last(args) instanceof Object) {
        const params = args.slice(0, args.length - 1)
        const query = last(args)
        const queryString = qs.stringify(query)

        absolutePath = `${this}/${pathMethod(...params)}?${queryString}`
      } else {
        absolutePath = `${this}/${pathMethod(...args)}`
      }

      return new Path(absolutePath.replace(/\/+/, "/"))
    }
  }

  landingPath = this.makePath(() => `home`)
  listPath = this.makePath(() => `claim-list`)
  dashboardPath = this.makePath(() => `dashboard`)
  dashboardDetailPath = this.makePath((id: string) => `dashboard/detail/${id}`)
  successPath = this.makePath(() => `claim-success`)
  claimDetailPath = this.makePath(() => `claim-detail`)

  historyPath = this.makePath(() => `history`)
  historyDetailPath = this.makePath((id: string) => `history/detail/${id}`)

  claimRequestsPath = this.makePath(() => `claim-requests`)
  approvalRequestPath = this.makePath((id: string) => `approval-request/${id}`)

  page404Path = this.makePath(() => `404`)
  page500Path = this.makePath(() => `500`)
  page498Path = this.makePath(() => `498`)

  // signInPath              = this.makePath(() => `auth/sign-in`)
  // signUpPath              = this.makePath(() => `auth/sign-up`)
  // setupPasswordPath       = this.makePath(() => `auth/setup-password`)
  // resetPasswordPath       = this.makePath(() => `auth/reset-password`)

  // adminsPath              = this.makePath(() => `admins`)
  // adminNewPath            = this.makePath(() => `admins/new`)
  // adminEditPath           = this.makePath((id: any) => `admins/${id}/edit`)

  // bookingsPath            = this.makePath(() => `bookings`)
  // bookingNewPath          = this.makePath(() => `bookings/new`)
  // bookingEditPath         = this.makePath((id: any) => `bookings/${id}/edit`)

  // profilePath             = this.makePath(() => `profile`)
  // settingPath             = this.makePath(() => `setting`)

  // termsAndConditionsPath  = this.makePath(() => `terms_and_conditions`)

  // supplierPath            = this.makePath((id: any) => `supplier/${id}`)

  homePath = this.landingPath
}

export default new Path()
